import { Card, CardContent, CardTitle } from "@/components/ui/card"

import { MapIcon } from "./map-icon"

export function MapLegends() {
  return (
    <Card className="space-y-2 p-3 text-sm">
      <CardTitle className="text-base">Legend</CardTitle>
      <CardContent className="grid grid-cols-3 gap-1 p-0 md:grid-cols-1">
        <div className="flex items-center gap-2">
          <MapIcon connectionStatus="Active" />
          <span>Active</span>
        </div>
        <div className="flex items-center gap-2">
          <MapIcon connectionStatus="Disconnected" />
          <span>Disconnected</span>
        </div>
        <div className="flex items-center gap-2">
          <MapIcon connectionStatus="Delinquent" />
          <span>Delinquent</span>
        </div>
        <div className="flex items-center gap-2">
          <MapIcon connectionStatus="Pending" />
          <span>Pending</span>
        </div>
        <div className="flex items-center gap-2">
          <MapIcon connectionStatus="Before Due" />
          <span>Before Due</span>
        </div>
        <div className="flex items-center gap-2">
          <MapIcon connectionStatus="Due Today" />
          <span>Due Today</span>
        </div>
        <div className="flex items-center gap-2">
          <MapIcon connectionStatus="Over Due" />
          <span>Over Due</span>
        </div>
      </CardContent>
    </Card>
  )
}
