import { useEffect, useState } from "react"
import { zodResolver } from "@hookform/resolvers/zod"
import { createFileRoute, useNavigate } from "@tanstack/react-router"
import Cookies from "js-cookie"
import { useForm } from "react-hook-form"
import { z } from "zod"

import { env } from "@/env"
import { sendMfaOtp } from "@/lib/api/auth"
import { getUserEmail } from "@/lib/auth"
import { toast } from "@/hooks/use-toast"
import { Button } from "@/components/ui/button"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from "@/components/ui/input-otp"

export const Route = createFileRoute("/_auth/mfa-auth")({
  component: Page,
})

const FormSchema = z.object({
  pin: z.string().min(6, {
    message: "Your one-time password must be 6 characters.",
  }),
})

export function Page() {
  const navigate = useNavigate()
  const [timeRemaining, setTimeRemaining] = useState<number | null>(null)
  const [canResend, setCanResend] = useState(false)
  const email = getUserEmail()
  const mfaAuthCookie = `mfa-auth-${email}`

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      pin: "",
    },
  })

  useEffect(() => {
    const interval = setInterval(() => {
      const otpSetTime = Cookies.get("otp-set-time")

      if (otpSetTime) {
        const otpSetTimestamp = Number(otpSetTime)
        const currentTime = new Date().getTime()
        const expirationTime = 5 * 60 * 1000
        const remaining =
          (otpSetTimestamp + expirationTime - currentTime) / 1000

        setTimeRemaining(remaining > 0 ? remaining : null)
        setCanResend(remaining <= 0)
      } else {
        setTimeRemaining(null)
        setCanResend(true)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  function onSubmit(data: z.infer<typeof FormSchema>) {
    const otp = Cookies.get("otp")
    if (data.pin === otp) {
      Cookies.set(mfaAuthCookie, "true", {
        expires: (1 / 1440) * env.SESSION_DURATION,
      })
      navigate({ to: "/" })
    } else {
      toast({
        title: "Incorrect OTP",
        description: "Please try again.",
        variant: "destructive",
      })
    }
  }

  async function resendCode() {
    if (canResend) {
      try {
        await sendMfaOtp()

        toast({
          title: "OTP Resent",
          description: "A new code has been sent to your email.",
        })

        setCanResend(false)
      } catch (error) {
        toast({
          title: "Error",
          description:
            "There was an error resending the OTP. Please try again.",
          variant: "destructive",
        })
      }
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-2/3 space-y-6">
        <FormField
          control={form.control}
          name="pin"
          render={({ field }) => (
            <FormItem className="flex flex-col items-center text-center">
              <FormLabel className="text-black">MFA Authentication</FormLabel>
              <FormControl>
                <InputOTP maxLength={6} {...field}>
                  <InputOTPGroup>
                    <InputOTPSlot className="text-black" index={0} />
                    <InputOTPSlot className="text-black" index={1} />
                    <InputOTPSlot className="text-black" index={2} />
                    <InputOTPSlot className="text-black" index={3} />
                    <InputOTPSlot className="text-black" index={4} />
                    <InputOTPSlot className="text-black" index={5} />
                  </InputOTPGroup>
                </InputOTP>
              </FormControl>
              <FormDescription>
                Please enter the one-time password sent to your email.
              </FormDescription>
              <FormMessage />
              {timeRemaining !== null && (
                <p className="py-2 text-sm text-muted-foreground">
                  Expires in: {Math.ceil(timeRemaining)} seconds
                </p>
              )}
            </FormItem>
          )}
        />

        <Button type="submit" className="w-full">
          Submit
        </Button>

        <div className="flex items-center justify-center text-sm text-muted-foreground">
          Didn&apos;t receive a code?{" "}
          <Button
            type="button"
            onClick={resendCode}
            disabled={!canResend}
            variant="link"
            className="text-primary"
          >
            Resend
          </Button>
        </div>
      </form>
    </Form>
  )
}
