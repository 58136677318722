import type { MeterInfoType } from "@/types"
import { ColumnDef } from "@tanstack/react-table"
import { format } from "date-fns"

import { DataTableColumnHeader } from "../data-table"
import { Badge } from "../ui/badge"
import { MeterInfoOperations } from "./meter-info-operations"

export const meterInfoColumns: ColumnDef<MeterInfoType>[] = [
  {
    accessorKey: "serial_number",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Serial No." />
    ),
    cell: ({ row }) => {
      const value = row.original.serial_number
      return <div>{value}</div>
    },
  },
  {
    accessorKey: "brand",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Brand" />
    ),
    cell: ({ row }) => {
      const value = row.original.brand
      return <div>{value}</div>
    },
  },
  {
    accessorKey: "model",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Model" />
    ),
    cell: ({ row }) => {
      const value = row.original.model
      return <div>{value}</div>
    },
  },
  {
    accessorKey: "size",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Size" />
    ),
    cell: ({ row }) => {
      const value = row.original.size
      return <div>{value}</div>
    },
  },
  {
    accessorKey: "status",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Status" />
    ),
    cell: ({ row }) => {
      const value = row.original.status
      return <Badge variant="outline">{value || "N/A"}</Badge>
    },
  },
  {
    accessorKey: "purchase_date",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Purchase Date" />
    ),
    cell: ({ row }) => {
      const value = row.original.purchase_date
      return (
        <div>{value ? format(new Date(value), "MMM dd, yyyy") : "N/A"}</div>
      )
    },
  },
  {
    accessorKey: "decommission_date",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Decommission Date" />
    ),
    cell: ({ row }) => {
      const value = row.original.decommission_date
      return (
        <div>{value ? format(new Date(value), "MMM dd, yyyy") : "N/A"}</div>
      )
    },
  },
  {
    accessorKey: "actions",
    header: "Actions",
    cell: ({ row }) => {
      return (
        <div>
          <MeterInfoOperations data={row.original} />
        </div>
      )
    },
  },
]
