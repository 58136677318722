import * as React from "react"
import type { ConsumerBillType } from "@/types"
import { useQuery } from "@tanstack/react-query"
import { createFileRoute } from "@tanstack/react-router"
import type { DateRange } from "react-day-picker"

import { getConsumerBill } from "@/lib/api/billing"
import { DataTable } from "@/components/data-table"
import { DateRangePicker } from "@/components/date-range-picker"
import { Header } from "@/components/header"
import { consumerBillColumns } from "@/components/table/consumer-bill-columns"

export const Route = createFileRoute("/_home/billing")({
  component: GenerateBillPage,
})

function GenerateBillPage() {
  const [date, setDate] = React.useState<DateRange>({
    from: new Date(new Date().setMonth(new Date().getMonth() - 1)),
    to: new Date(),
  })

  const {
    data: consumerBilling,
    isLoading,
    isError,
  } = useQuery<ConsumerBillType[]>({
    queryKey: ["consumerBill", date],
    queryFn: async () => {
      const from = date.from ?? new Date()
      const to = date.to ?? new Date()
      return await getConsumerBill(from.toISOString(), to.toISOString())
    },
  })

  return (
    <main className="space-y-4 p-4">
      <Header>Billing</Header>
      <DataTable
        columns={consumerBillColumns}
        data={consumerBilling ?? []}
        search={{
          field: "name",
          label: "name",
        }}
        isLoading={isLoading}
        isError={isError}
      >
        <div className="flex w-auto justify-end md:w-full">
          <DateRangePicker date={date} setDate={setDate} />
        </div>
      </DataTable>
    </main>
  )
}
