import type {
  BillingAdjustmentType,
  BillingInfoType,
  BillingOverviewType,
  BooksZoneType,
  ClientZoneType,
  ConsumerBillType,
  ConsumerConnectionBillingStatusType,
  ConsumerDetailsType,
  ConsumerInfoType,
  ConsumersListType,
  EditConsumerBillingType,
  GenerateBillType,
  MeterInfoAnalyticsType,
  MeterInfoType,
  MeterReaderZoneType,
  MeterSizeType,
  PaymentAnalysisType,
  PaymentDetailsType,
  RecentPendingPaymentsType,
  SubmitConsumerInfoType,
  WeeklyPaymentsChartType,
  WeeklyPaymentsType,
} from "@/types"
import { z } from "zod"

import { getClientId } from "../auth"
import { meterSchema } from "../schema/meter"
import { apiFetch } from "../utils"
import { postActivityLog } from "./log"

const billingUrl = `himo-billing/`

const editMeterSchema = meterSchema.extend({
  purchase_date: z.date().optional(),
  decommission_date: z.date().optional(),
})

// Data Fetching
export async function getTotalConsumers(): Promise<{
  total_consumers: number
}> {
  const clientId = getClientId()

  const response = await apiFetch(
    `${billingUrl}get-total-consumers?client_id=${clientId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
  )
  if (!response.ok) {
    throw new Error(`Error fetching total consumers: ${response.statusText}`)
  }
  return response.json()
}

export async function getTotalActiveConsumers(): Promise<{
  total_active_consumers: number
}> {
  const clientId = getClientId()

  const response = await apiFetch(
    `${billingUrl}get-total-active-consumers?client_id=${clientId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
  )
  if (!response.ok) {
    throw new Error(
      `Error fetching total active consumers: ${response.statusText}`,
    )
  }
  return response.json()
}

export async function getTotalPayments(): Promise<{ total_payments: string }> {
  const today = new Date().toISOString().split("T")[0]
  const response = await apiFetch(`${billingUrl}get-total-payments/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ date: today }),
  })
  if (!response.ok) {
    throw new Error(`Error fetching total payments: ${response.statusText}`)
  }
  return response.json()
}

export async function getRecentPayments() {
  const response = await apiFetch(`${billingUrl}get-recent-payment/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
  if (!response.ok) {
    throw new Error(
      `Error fetching recent pending payments: ${response.statusText}`,
    )
  }
  return response.json()
}

export async function getRecentPendingPayments(): Promise<
  RecentPendingPaymentsType[]
> {
  const response = await apiFetch(`${billingUrl}get-recent-pending-payment/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
  if (!response.ok) {
    throw new Error(`Error fetching recent payments: ${response.statusText}`)
  }
  return response.json()
}

export async function getBillingOverview(): Promise<BillingOverviewType> {
  try {
    const [
      { total_consumers },
      { total_active_consumers },
      { total_payments },
    ] = await Promise.all([
      getTotalConsumers(),
      getTotalActiveConsumers(),
      getTotalPayments(),
    ])

    return {
      total_consumers,
      total_active_consumers,
      total_payments,
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(`Error fetching billing overview: ${error.message}`)
    } else {
      throw new Error(
        "An unknown error occurred while fetching billing overview",
      )
    }
  }
}

export async function getWeeklyPayments(): Promise<WeeklyPaymentsChartType[]> {
  const response = await apiFetch(`${billingUrl}get-weekly-payment/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
  if (!response.ok) {
    throw new Error(`Error fetching weekly payments: ${response.statusText}`)
  }
  const data: WeeklyPaymentsType = await response.json()
  return data.series_data.map((item) => ({
    time: item[0],
    paid: item[1],
    pending: item[2],
  }))
}

export async function getPaymentAnalysis(
  startDate: Date,
  endDate: Date,
): Promise<PaymentAnalysisType[]> {
  const response = await apiFetch(`${billingUrl}payment-analysis/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      from_date: startDate,
      to_date: endDate,
    }),
  })
  if (!response.ok) {
    throw new Error(`Error fetching payment analysis: ${response.statusText}`)
  }
  return response.json()
}

export async function getTotalMonthlyPayments(): Promise<{
  total_payments: string
}> {
  const response = await apiFetch(`${billingUrl}get-total-payments/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ date: "month" }),
  })
  if (!response.ok) {
    throw new Error(`Error fetching total payments: ${response.statusText}`)
  }
  return response.json()
}

export async function getConsumerBill(
  startDate: string,
  endDate: string,
): Promise<ConsumerBillType[]> {
  const clientId = getClientId()

  const response = await apiFetch(
    `${billingUrl}get-consumer-bill/?client=${clientId}&from_date=${startDate}&to_date=${endDate}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
  )
  if (!response.ok) {
    throw new Error(`Error fetching consumer bill: ${response.statusText}`)
  }
  return response.json()
}

export async function getBillPerConsumer(
  consumerId: number,
  startDate: string,
  endDate: string,
): Promise<ConsumerBillType[]> {
  const clientId = getClientId()

  const response = await apiFetch(
    `${billingUrl}get-bill-per-consumer/?client=${clientId}&consumer_id=${consumerId}&from_date=${startDate}&to_date=${endDate}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
  )
  if (!response.ok) {
    throw new Error(`Error fetching consumer bill: ${response.statusText}`)
  }
  return response.json()
}

export async function getConsumerDetails(): Promise<ConsumerDetailsType[]> {
  const clientId = getClientId()

  const response = await apiFetch(
    `${billingUrl}get-consumer-details?client_id=${clientId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
  )
  if (!response.ok) {
    throw new Error(`Error fetching consumer details: ${response.statusText}`)
  }
  return response.json()
}

export async function getPaymentHistory(
  startDate: string,
  endDate: string,
): Promise<PaymentDetailsType[]> {
  const response = await apiFetch(`${billingUrl}get-payment-history/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      start_date: startDate,
      end_date: endDate,
    }),
  })
  if (!response.ok) {
    throw new Error(`Error fetching payment history: ${response.statusText}`)
  }
  return response.json()
}

export async function getAllConsumers(): Promise<ConsumersListType[]> {
  const clientId = getClientId()

  const response = await apiFetch(
    `${billingUrl}get-all-consumers?client_id=${clientId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
  )
  if (!response.ok) {
    throw new Error(`Error fetching all consumers: ${response.statusText}`)
  }
  return response.json()
}

export async function getClientZones() {
  const clientId = getClientId()

  const response = await apiFetch(`${billingUrl}get-client-zones/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ client_id: clientId }),
  })
  if (!response.ok) {
    throw new Error(`Error fetching client zones: ${response.statusText}`)
  }

  const data = await response.json()
  return data.map((zone: ClientZoneType) => ({
    id: zone.zone_id,
    label: zone.zone_name,
    value: zone.zone_name,
  }))
}

export async function getBooksZone(zoneId: number) {
  const response = await apiFetch(`${billingUrl}get-books-zone/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ zone_id: zoneId }),
  })
  if (!response.ok) {
    throw new Error(`Error fetching books zone: ${response.statusText}`)
  }

  const data = await response.json()
  return data.map((book: BooksZoneType) => ({
    id: book.id,
    label: book.name,
    value: book.name,
  }))
}

export async function getMeterReaderZone(zoneId: number) {
  const response = await apiFetch(`${billingUrl}get-meter-reader-zone/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ zone_id: zoneId }),
  })
  if (!response.ok) {
    throw new Error(`Error fetching meter reader zone: ${response.statusText}`)
  }

  const data = await response.json()
  return data.map((reader: MeterReaderZoneType) => ({
    id: reader.id,
    label: reader.full_name,
    value: reader.full_name,
  }))
}

export async function getConnectionType() {
  const response = await apiFetch(`${billingUrl}get-connection-type/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
  if (!response.ok) {
    throw new Error(`Error fetching connection type: ${response.statusText}`)
  }

  const data: Omit<BooksZoneType, "zone">[] = await response.json()
  return data.map((item: Omit<BooksZoneType, "zone">) => ({
    id: item.id,
    label: item.name,
    value: item.name,
  }))
}

export async function getConnectionStatus() {
  const response = await apiFetch(`${billingUrl}get-connection-status/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
  if (!response.ok) {
    throw new Error(`Error fetching connection status: ${response.statusText}`)
  }

  const data: Omit<BooksZoneType, "zone">[] = await response.json()
  return data.map((item: Omit<BooksZoneType, "zone">) => ({
    id: item.id,
    label: item.name,
    value: item.name,
  }))
}

export async function getConsumer(
  consumerId: number,
): Promise<ConsumerInfoType> {
  const clientId = getClientId()

  const response = await apiFetch(
    `${billingUrl}get-consumer/${consumerId}?client_id=${clientId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
  )
  if (!response.ok) {
    throw new Error(`Error fetching consumer: ${response.statusText}`)
  }
  return response.json()
}

export async function getConnectionAndBillingStatus(): Promise<
  ConsumerConnectionBillingStatusType[]
> {
  const clientId = getClientId()

  const response = await apiFetch(
    `${billingUrl}get-connection-and-billing-status/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ user_client_id: clientId }),
    },
  )
  if (!response.ok) {
    throw new Error(`Error fetching: ${response.statusText}`)
  }

  return (await response.json()).consumers
}

export async function getMeterInfo(): Promise<MeterInfoType[]> {
  const response = await apiFetch(`${billingUrl}get-meter-info-list/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
  if (!response.ok) {
    throw new Error(`Error fetching: ${response.statusText}`)
  }
  return response.json()
}

export async function getMeterSize(connectionTypeId: number) {
  const response = await apiFetch(
    `${billingUrl}get-meter-size?connection_type_id=${connectionTypeId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
  )
  if (!response.ok) {
    throw new Error(`Error fetching: ${response.statusText}`)
  }

  const data = await response.json()
  return data.map((item: MeterSizeType) => ({
    id: item.id,
    label: item.size,
    value: item.size,
  }))
}

export async function getMeterStatus() {
  const response = await apiFetch(`${billingUrl}get-meter-status/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
  if (!response.ok) {
    throw new Error(`Error fetching: ${response.statusText}`)
  }

  const data: {
    meter_status_id: string
    name: string
  }[] = await response.json()
  return data.map((item: { meter_status_id: string; name: string }) => ({
    id: item.meter_status_id,
    label: item.name,
    value: item.name,
  }))
}

export async function getTotalActiveMeter(): Promise<{
  total_active_meters: number
}> {
  const response = await apiFetch(`${billingUrl}get-total-active-meter/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
  if (!response.ok) {
    throw new Error(`Error fetching: ${response.statusText}`)
  }
  return response.json()
}

export async function getTotalInactiveMeter(): Promise<{
  total_inactive_meters: number
}> {
  const response = await apiFetch(`${billingUrl}get-total-inactive-meter/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
  if (!response.ok) {
    throw new Error(`Error fetching: ${response.statusText}`)
  }
  return response.json()
}

export async function getTotalDecommissionedMeter(): Promise<{
  total_decommissioned_meters: number
}> {
  const response = await apiFetch(
    `${billingUrl}get-total-decommissioned-meter/`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
  )
  if (!response.ok) {
    throw new Error(`Error fetching: ${response.statusText}`)
  }
  return response.json()
}

export async function getTotalUnassignedMeter(): Promise<{
  total_unassigned_meters: number
}> {
  const response = await apiFetch(`${billingUrl}get-total-unassigned-meter/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
  if (!response.ok) {
    throw new Error(`Error fetching: ${response.statusText}`)
  }
  return response.json()
}

export async function getMeterInfoAnalytics(
  startDate: string,
  endDate: string,
): Promise<MeterInfoAnalyticsType[]> {
  const response = await apiFetch(
    `${billingUrl}meter-info-analytics?from_date=${startDate}&to_date=${endDate}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
  )
  if (!response.ok) {
    throw new Error(`Error fetching: ${response.statusText}`)
  }
  return response.json()
}

export async function getBillingAdjustmentList(): Promise<
  BillingAdjustmentType[]
> {
  const clientId = getClientId()
  const response = await apiFetch(
    `${billingUrl}billing-adjustment-list?client_id=${clientId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
  )
  if (!response.ok) {
    throw new Error(`Error fetching: ${response.statusText}`)
  }
  return response.json()
}

// Operations
export async function generatePdfBill(data: GenerateBillType) {
  const response = await apiFetch(`${billingUrl}generate-pdf-bill/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
  if (!response.ok) {
    await postActivityLog({
      menu_path: "/billing",
      sub_menu_path: "",
      action: `Generate Bill (${data.account_num})`,
      status_code: response.status,
      api_endpoint: response.url,
      web_url: window.location.href,
    })

    throw new Error(`Error: ${response.statusText}`)
  }

  await postActivityLog({
    menu_path: "/billing",
    sub_menu_path: "",
    action: `Generate Bill (${data.account_num})`,
    status_code: response.status,
    api_endpoint: response.url,
    web_url: window.location.href,
  })

  return response.json()
}

export async function addConsumer(data: SubmitConsumerInfoType) {
  const response = await apiFetch(`${billingUrl}add-consumer/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
  if (!response.ok) {
    await postActivityLog({
      menu_path: "/manage-users",
      sub_menu_path: "",
      action: `Add Consumer (${data.full_name})`,
      status_code: response.status,
      api_endpoint: response.url,
      web_url: window.location.href,
    })

    throw new Error(`Error: ${response.statusText}`)
  }

  await postActivityLog({
    menu_path: "/manage-users",
    sub_menu_path: "",
    action: `Add Consumer (${data.full_name})`,
    status_code: response.status,
    api_endpoint: response.url,
    web_url: window.location.href,
  })

  return response.json()
}

export async function editConsumer(id: number, data: SubmitConsumerInfoType) {
  const response = await apiFetch(`${billingUrl}edit-consumer/${id}/`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })

  if (!response.ok) {
    await postActivityLog({
      menu_path: "/manage-users",
      sub_menu_path: "",
      action: `Edit Consumer (ID ${id})`,
      status_code: response.status,
      api_endpoint: response.url,
      web_url: window.location.href,
    })

    throw new Error(`Error: ${response.statusText}`)
  }

  const result = await response.json()

  await postActivityLog({
    menu_path: "/manage-users",
    sub_menu_path: "",
    action: `Edit Consumer (ID ${id})`,
    status_code: response.status,
    api_endpoint: response.url,
    web_url: window.location.href,
  })

  return result
}

export async function deleteConsumer(id: number) {
  const response = await apiFetch(`${billingUrl}delete-consumer/${id}/`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  })

  if (!response.ok) {
    await postActivityLog({
      menu_path: "/manage-users",
      sub_menu_path: "",
      action: `Delete Consumer (ID ${id})`,
      status_code: response.status,
      api_endpoint: response.url,
      web_url: window.location.href,
    })

    throw new Error(`Error: ${response.statusText}`)
  }

  await postActivityLog({
    menu_path: "/manage-users",
    sub_menu_path: "",
    action: `Delete Consumer (ID ${id})`,
    status_code: response.status,
    api_endpoint: response.url,
    web_url: window.location.href,
  })

  if (response.status === 204) {
    return null
  }

  const contentType = response.headers.get("content-type")
  if (contentType && contentType.includes("application/json")) {
    return response.json()
  } else {
    return null
  }
}

export async function payBill(data: BillingInfoType) {
  const response = await apiFetch(`${billingUrl}pay-bill/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
  if (!response.ok) {
    await postActivityLog({
      menu_path: "/billing",
      sub_menu_path: "",
      action: `Pay Bill (₱${data.amount_paid})`,
      status_code: response.status,
      api_endpoint: response.url,
      web_url: window.location.href,
    })

    throw new Error(`Error: ${response.statusText}`)
  }

  await postActivityLog({
    menu_path: "/billing",
    sub_menu_path: "",
    action: `Pay Bill (₱${data.amount_paid})`,
    status_code: response.status,
    api_endpoint: response.url,
    web_url: window.location.href,
  })

  return response.json()
}

export async function addMeter(data: z.infer<typeof meterSchema>) {
  const response = await apiFetch(`${billingUrl}add-meter-info/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
  if (!response.ok) {
    await postActivityLog({
      menu_path: "/meter-info",
      sub_menu_path: "",
      action: `Add Meter (${data.serial_number})`,
      status_code: response.status,
      api_endpoint: response.url,
      web_url: window.location.href,
    })

    throw new Error(`Error: ${response.statusText}`)
  }

  await postActivityLog({
    menu_path: "/meter-info",
    sub_menu_path: "",
    action: `Add Meter (${data.serial_number})`,
    status_code: response.status,
    api_endpoint: response.url,
    web_url: window.location.href,
  })

  return response.json()
}

export async function editMeter(
  id: number,
  data: z.infer<typeof editMeterSchema>,
) {
  const response = await apiFetch(`${billingUrl}update-meter-info/${id}/`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })

  if (!response.ok) {
    await postActivityLog({
      menu_path: "/meter-info",
      sub_menu_path: "",
      action: `Edit Meter (ID ${id})`,
      status_code: response.status,
      api_endpoint: response.url,
      web_url: window.location.href,
    })

    throw new Error(`Error: ${response.statusText}`)
  }

  const result = await response.json()

  await postActivityLog({
    menu_path: "/meter-info",
    sub_menu_path: "",
    action: `Edit Meter (ID ${id})`,
    status_code: response.status,
    api_endpoint: response.url,
    web_url: window.location.href,
  })

  return result
}

export async function deleteMeter(id: number) {
  const response = await apiFetch(`${billingUrl}delete-meter-info/${id}/`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  })

  if (!response.ok) {
    await postActivityLog({
      menu_path: "/meter-info",
      sub_menu_path: "",
      action: `Delete Meter (ID ${id})`,
      status_code: response.status,
      api_endpoint: response.url,
      web_url: window.location.href,
    })

    throw new Error(`Error: ${response.statusText}`)
  }

  await postActivityLog({
    menu_path: "/meter-info",
    sub_menu_path: "",
    action: `Delete Meter (ID ${id})`,
    status_code: response.status,
    api_endpoint: response.url,
    web_url: window.location.href,
  })

  if (response.status === 204) {
    return null
  }

  const contentType = response.headers.get("content-type")
  if (contentType && contentType.includes("application/json")) {
    return response.json()
  } else {
    return null
  }
}

export async function editConsumerBilling(
  id: number,
  data: EditConsumerBillingType,
) {
  const response = await apiFetch(
    `${billingUrl}consumer-billing/update/${id}/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    },
  )

  if (!response.ok) {
    await postActivityLog({
      menu_path: "/meter-info",
      sub_menu_path: "",
      action: `Edit Consumer Billing (ID ${id})`,
      status_code: response.status,
      api_endpoint: response.url,
      web_url: window.location.href,
    })

    throw new Error(`Error: ${response.statusText}`)
  }

  const result = await response.json()

  await postActivityLog({
    menu_path: "/meter-info",
    sub_menu_path: "",
    action: `Edit Consumer Billing (ID ${id})`,
    status_code: response.status,
    api_endpoint: response.url,
    web_url: window.location.href,
  })

  return result
}
