import { apiFetch } from "../utils"

interface RegionType {
  region_code: number
  region: string
  description: string
  acronym: string
}

interface ProvinceType {
  province_code: number
  province: string
  region_code: number
}

interface MunicipalityType {
  municipality_code: number
  municipality: string
  province_code: number
}

interface BarangayType {
  barangay_code: number
  barangay: string
  municipality_code: number
}

export async function getRegions() {
  const response = await apiFetch(`get-regions/`)
  if (!response.ok) {
    throw new Error(`Error fetching: ${response.statusText}`)
  }

  const data = await response.json()
  return data.map((item: RegionType) => ({
    id: item.region_code,
    label: item.description,
    value: item.description,
  }))
}

export async function getProvinces(id: number) {
  const response = await apiFetch(`get-provinces/`, {
    method: "POST",
    body: JSON.stringify({ region_code: id }),
  })
  if (!response.ok) {
    throw new Error(`Error fetching: ${response.statusText}`)
  }

  const data = await response.json()
  return data.map((item: ProvinceType) => ({
    id: item.province_code,
    label: item.province,
    value: item.province,
  }))
}

export async function getMunicipalities(id: number) {
  const response = await apiFetch(`get-municipalities/`, {
    method: "POST",
    body: JSON.stringify({ province_code: id }),
  })
  if (!response.ok) {
    throw new Error(`Error fetching: ${response.statusText}`)
  }

  const data = await response.json()
  return data.map((item: MunicipalityType) => ({
    id: item.municipality_code,
    label: item.municipality,
    value: item.municipality,
  }))
}

export async function getBarangays(id: number) {
  const response = await apiFetch(`get-barangays/`, {
    method: "POST",
    body: JSON.stringify({ municipality_code: id }),
  })
  if (!response.ok) {
    throw new Error(`Error fetching: ${response.statusText}`)
  }

  const data = await response.json()
  return data.map((item: BarangayType) => ({
    id: item.barangay_code,
    label: item.barangay,
    value: item.barangay,
  }))
}
