import { SiteConfigType, SystemConfigType } from "@/types"

export const siteConfig: SiteConfigType = {
  name: "HIMO Billing",
  description: "Hirayatech System for Read and Bill",
  author: "hirayatech",
}

export const systemConfig: SystemConfigType = {
  hirayaSystemId: 3,
}
