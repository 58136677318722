import * as React from "react"

import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { Icons } from "@/components/icons"

interface Option {
  value: string
  label: string
  id?: string | number
}

interface ComboboxSearchProps {
  modal?: boolean
  field: any
  options: Option[]
  placeholder?: string
  onSelectId?: (id?: string | number) => void
  idAsValue?: boolean
}

export function ComboboxSearch({
  modal = false,
  field,
  options,
  placeholder,
  onSelectId,
  idAsValue = false,
}: ComboboxSearchProps) {
  const [open, setOpen] = React.useState(false)
  const selectedLabel = idAsValue
    ? options.find((option) => option.id === field.value)?.label
    : options.find((option) => option.value === field.value)?.label

  return (
    <Popover open={open} onOpenChange={setOpen} modal={modal}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          className={cn(
            "justify-between font-normal",
            !field.value && "text-muted-foreground",
          )}
        >
          {/* Render the label of the selected item */}
          {selectedLabel || placeholder}
          <Icons.updown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0">
        <Command>
          <CommandInput placeholder="Search..." />
          <CommandList>
            <CommandEmpty>Nothing found.</CommandEmpty>

            <CommandGroup>
              {options.map((option) => (
                <CommandItem
                  value={option.label}
                  key={idAsValue ? option.id : option.value}
                  onSelect={() => {
                    if (idAsValue) {
                      field.onChange(option.id)
                    } else {
                      field.onChange(option.value)
                    }

                    if (onSelectId) {
                      onSelectId(option.id)
                    }

                    setOpen(false)
                  }}
                >
                  <Icons.checkCircle
                    className={cn(
                      "mr-2 h-4 w-4",
                      (
                        idAsValue
                          ? option.id === field.value
                          : option.value === field.value
                      )
                        ? "opacity-100"
                        : "opacity-0",
                    )}
                  />
                  {option.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
