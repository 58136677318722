import { ChartConfig } from "@/components/ui/chart"

export const chartConfig = {
  paid: {
    label: "Paid",
    color: "#00B6AE",
  },
  pending: {
    label: "Pending",
    color: "#4169E1",
  },
  "Payments Done": {
    label: "Payments Done",
    color: "#00B6AE",
  },
  "Payments Pending": {
    label: "Payments Pending",
    color: "#4169E1",
  },
  y: {
    label: "Percentage",
    color: "#FFFFFF",
  },
  NaN: {
    label: "No data",
    color: "#FFFFFF",
  },
  active_count: {
    label: "Active",
    color: "#00B6AE",
  },
  inactive_count: {
    label: "Inactive",
    color: "#F89C4D",
  },
  decommissioned_count: {
    label: "Decommissioned",
    color: "#84D0D3",
  },
  unassigned_count: {
    label: "Unassigned",
    color: "#92C83E",
  },
  data: {
    label: "Count",
    color: "#00B6AE",
  },
} satisfies ChartConfig
