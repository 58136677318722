import type { ConsumerDetailsType } from "@/types"
import { Link } from "@tanstack/react-router"
import { ColumnDef } from "@tanstack/react-table"

import { Button } from "@/components/ui/button"
import { Icons } from "@/components/icons"

import { ConsumerDetailsOperations } from "./consumer-details-operations"

export const consumerDetailsColumns: ColumnDef<ConsumerDetailsType>[] = [
  {
    accessorKey: "consumer_id",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          ID
          <Icons.sort className="ml-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const value = row.original.consumer_id
      return (
        <div className="px-4 py-2 text-right md:py-0 md:text-left">{value}</div>
      )
    },
  },
  {
    accessorKey: "name",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Name
          <Icons.sort className="ml-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const value = row.original.name
      return (
        <Link
          to={`/manage-users/view/${row.original.consumer_id}`}
          className="px-4 py-2 text-right hover:underline md:py-0 md:text-left"
        >
          {value}
        </Link>
      )
    },
  },
  {
    accessorKey: "address",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Address
          <Icons.sort className="ml-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const value = row.original.address
      return (
        <div className="max-w-[220px] truncate px-4 py-2 text-right md:max-w-full md:py-0 md:text-left">
          {value}
        </div>
      )
    },
  },
  {
    accessorKey: "acc_id",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Acc No.
          <Icons.sort className="ml-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const value = row.original.acc_id
      return (
        <div className="px-4 py-2 text-right md:py-0 md:text-left">{value}</div>
      )
    },
  },
  {
    accessorKey: "contact_num",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Contact No.
          <Icons.sort className="ml-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const value = row.original.contact_num
      return (
        <div className="px-4 py-2 text-right md:py-0 md:text-left">{value}</div>
      )
    },
  },
  {
    accessorKey: "actions",
    header: () => {
      return <Button variant="ghost">Actions</Button>
    },
    cell: ({ row }) => {
      return (
        <div className="px-4 py-2 text-right md:py-0 md:text-left">
          <ConsumerDetailsOperations data={row.original} />
        </div>
      )
    },
  },
]
