import * as React from "react"
import type { UserInfoType } from "@/types"
import { useQuery } from "@tanstack/react-query"
import { Link, useNavigate } from "@tanstack/react-router"
import Cookies from "js-cookie"

import { siteConfig } from "@/config/site"
import { fetchMenuTabs } from "@/lib/api/menu"
import { logout } from "@/lib/auth"
import { Avatar, AvatarFallback } from "@/components/ui/avatar"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Label } from "@/components/ui/label"
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarHeader,
  SidebarInput,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
  SidebarRail,
  SidebarTrigger,
  useSidebar,
} from "@/components/ui/sidebar"
import { Icons } from "@/components/icons"
import { ModeToggle } from "@/components/mode-toggle"

export function AppSidebar() {
  const [searchTerm, setSearchTerm] = React.useState("")
  const [openSubMenu, setOpenSubMenu] = React.useState<number | null>(null)
  const { setOpenMobile } = useSidebar()

  const navigate = useNavigate({ from: "/login" })
  const userInfoString = Cookies.get("user_info")
  let userInfo: UserInfoType | undefined

  if (userInfoString) {
    try {
      userInfo = JSON.parse(userInfoString) as UserInfoType
    } catch (error) {
      console.error("Failed to parse user_info cookie", error)
    }
  }

  const { data } = useQuery({
    queryKey: ["menuTabs"],
    queryFn: fetchMenuTabs,
  })

  function toLogin() {
    logout()
    navigate({ to: "/login" })
  }

  const handleToggleSubMenu = (id: number) => {
    setOpenSubMenu((prev) => (prev === id ? null : id))
  }

  const filteredMenuTabs = data?.filter((item) => {
    const matchesMainMenu = item.name
      .toLowerCase()
      .includes(searchTerm.toLowerCase())

    const matchingSubMenus = item.sub_menu?.filter((sub) =>
      sub.name.toLowerCase().includes(searchTerm.toLowerCase()),
    )

    return matchesMainMenu || (matchingSubMenus && matchingSubMenus.length > 0)
  })

  return (
    <>
      <Sidebar>
        <SidebarHeader>
          <SidebarMenu>
            <SidebarMenuItem className="flex items-center">
              <SidebarMenuButton size="lg" asChild>
                <Link to="/">
                  <div className="flex aspect-square size-8 items-center justify-center rounded-lg bg-sidebar-primary text-sidebar-primary-foreground">
                    <Icons.himo className="size-6" />
                  </div>
                  <div className="flex flex-col gap-0.5 leading-none">
                    <span className="font-semibold">{siteConfig.name}</span>
                  </div>
                </Link>
              </SidebarMenuButton>
              <ModeToggle />
            </SidebarMenuItem>
          </SidebarMenu>
          <form>
            <SidebarGroup className="flex py-0">
              <SidebarGroupContent className="relative">
                <Label htmlFor="search" className="sr-only">
                  Search
                </Label>
                <SidebarInput
                  id="search"
                  placeholder="Search"
                  className="pl-8"
                  autoComplete="off"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <Icons.search className="pointer-events-none absolute left-2 top-1/2 size-4 -translate-y-1/2 select-none opacity-50" />
              </SidebarGroupContent>
            </SidebarGroup>
          </form>
        </SidebarHeader>
        <SidebarContent>
          <SidebarGroup>
            <SidebarGroupContent>
              <SidebarMenu>
                {filteredMenuTabs?.length === 0 ? (
                  <div className="p-4 text-center text-muted-foreground">
                    No Results
                  </div>
                ) : (
                  filteredMenuTabs?.map((link) => {
                    const Icon = Icons[link.icon]
                      ? Icons[link.icon]
                      : () => null
                    const hasSubMenuItems =
                      link.sub_menu && link.sub_menu.length > 0
                    const Collapsible = hasSubMenuItems && Icons.right

                    return (
                      <React.Fragment key={link.id}>
                        <SidebarMenuItem>
                          <SidebarMenuButton asChild>
                            {hasSubMenuItems ? (
                              <button
                                onClick={() => handleToggleSubMenu(link.id)}
                                className={`flex items-center rounded-lg px-3 py-2 transition-all [&.active]:bg-muted [&.active]:font-semibold [&.active]:text-primary`}
                              >
                                <Icon />
                                {link.name}
                                {Collapsible && (
                                  <Collapsible
                                    className={`transition-transform duration-200 ${openSubMenu === link.id ? "rotate-90" : ""}`}
                                  />
                                )}
                              </button>
                            ) : (
                              <Link
                                to={link.menu_route}
                                className={`flex items-center rounded-lg px-3 py-2 transition-all [&.active]:bg-muted [&.active]:font-semibold [&.active]:text-primary`}
                                onClick={() => {
                                  setOpenMobile(false)
                                }}
                              >
                                <Icon />
                                {link.name}
                              </Link>
                            )}
                          </SidebarMenuButton>
                        </SidebarMenuItem>

                        {link.sub_menu && openSubMenu === link.id && (
                          <SidebarMenuSub>
                            {link.sub_menu.map((subLink) => (
                              <SidebarMenuSubItem key={subLink.id}>
                                <SidebarMenuSubButton asChild>
                                  <Link
                                    to={`${link.menu_route}${subLink.sub_menu_route}`}
                                    className={`flex items-center rounded-lg px-3 py-2 text-muted-foreground transition-all [&.active]:bg-muted [&.active]:font-semibold [&.active]:text-primary`}
                                    onClick={() => {
                                      setOpenMobile(false)
                                    }}
                                  >
                                    {subLink.name}
                                  </Link>
                                </SidebarMenuSubButton>
                              </SidebarMenuSubItem>
                            ))}
                          </SidebarMenuSub>
                        )}
                      </React.Fragment>
                    )
                  })
                )}
              </SidebarMenu>
            </SidebarGroupContent>
          </SidebarGroup>
        </SidebarContent>
        <SidebarFooter>
          <SidebarMenu>
            <SidebarMenuItem>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <SidebarMenuButton
                    size="lg"
                    className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
                  >
                    <Avatar className="h-8 w-8 rounded-lg">
                      <AvatarFallback className="rounded-lg">
                        <Icons.user />
                      </AvatarFallback>
                    </Avatar>
                    <div className="grid flex-1 text-left text-sm leading-tight">
                      <span className="truncate font-semibold">
                        {userInfo?.full_name}
                      </span>
                      <span className="truncate text-xs">
                        {userInfo?.email}
                      </span>
                    </div>
                    <Icons.updown className="ml-auto size-4" />
                  </SidebarMenuButton>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
                  side="bottom"
                  align="end"
                  sideOffset={4}
                >
                  <DropdownMenuLabel className="p-0 font-normal">
                    <div className="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
                      <Avatar className="h-8 w-8 rounded-lg">
                        <AvatarFallback className="rounded-lg">
                          <Icons.user />
                        </AvatarFallback>
                      </Avatar>
                      <div className="grid flex-1 text-left text-sm leading-tight">
                        <span className="truncate font-semibold">
                          {userInfo?.full_name}
                        </span>
                        <span className="truncate text-xs">
                          {userInfo?.email}
                        </span>
                      </div>
                    </div>
                  </DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  <DropdownMenuGroup>
                    <DropdownMenuItem onClick={() => toLogin()}>
                      <Icons.logout className="mr-1 size-4" />
                      Log out
                    </DropdownMenuItem>
                  </DropdownMenuGroup>
                </DropdownMenuContent>
              </DropdownMenu>
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarFooter>
        <SidebarRail />
      </Sidebar>
      <div className="fixed right-0 top-0 z-[500] p-2">
        <SidebarTrigger />
      </div>
    </>
  )
}
