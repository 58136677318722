import { z } from "zod"

export const meterSchema = z.object({
  serial_number: z
    .string()
    .min(1)
    .max(255)
    .regex(/^\d+$/, { message: "Serial number must be numeric" }),
  meter_status_id: z.coerce.number().min(1, "Meter status is required"),
  meter_size_id: z.coerce.number().min(1, "Meter size is required"),
  connection_type_id: z.coerce.number().min(1, "Connection type is required"),
  brand: z.string().min(1).max(255),
  model: z.string().min(1).max(255),
})
