import { createFileRoute } from "@tanstack/react-router"

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { MeterForm } from "@/components/forms/meter-form"
import { Header } from "@/components/header"

export const Route = createFileRoute("/_home/meter-info/add")({
  component: AddMetersPage,
})

function AddMetersPage() {
  return (
    <main className="space-y-4 p-4">
      <Header
        subroutes={[
          {
            route: "/meter-info",
            label: "Meter Information",
          },
        ]}
      >
        Add Meters
      </Header>
      <Card className="mx-auto max-w-[650px]">
        <CardHeader>
          <CardTitle>Add Meter</CardTitle>
          <CardDescription>Fill up meter details below.</CardDescription>
        </CardHeader>
        <CardContent>
          <MeterForm />
        </CardContent>
      </Card>
    </main>
  )
}
