import type { ConsumerBillType } from "@/types"
import { ColumnDef } from "@tanstack/react-table"

import { Button } from "@/components/ui/button"
import { Icons } from "@/components/icons"

import { SubmitPayment } from "../forms/submit-payment"
import { ConsumerBillOperations } from "./consumer-bill-operations"

export const consumerBillColumns: ColumnDef<ConsumerBillType>[] = [
  {
    accessorKey: "consumer_id",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          ID
          <Icons.sort className="ml-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const value = row.original.billing_id
      return (
        <div className="px-4 py-2 text-right md:py-0 md:text-left">{value}</div>
      )
    },
  },
  {
    accessorKey: "name",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Name
          <Icons.sort className="ml-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const value = row.original.name
      return (
        <div className="px-4 py-2 text-right md:py-0 md:text-left">{value}</div>
      )
    },
  },
  {
    accessorKey: "acc_id",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Acc No.
          <Icons.sort className="ml-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const value = row.original.acc_id
      return (
        <div className="px-4 py-2 text-right md:py-0 md:text-left">{value}</div>
      )
    },
  },
  {
    accessorKey: "units",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Units
          <Icons.sort className="ml-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const value = row.original.units
      return (
        <div className="px-4 py-2 text-right md:py-0 md:text-left">{value}</div>
      )
    },
  },
  {
    accessorKey: "date",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Date
          <Icons.sort className="ml-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const date = new Date(row.original.date as string)
      const formattedDate = Intl.DateTimeFormat("en-US", {
        weekday: "short",
        month: "long",
        day: "numeric",
        year: "numeric",
      }).format(date)
      return (
        <div className="px-4 py-2 text-right md:py-0 md:text-left">
          {formattedDate}
        </div>
      )
    },
  },
  {
    accessorKey: "due_date",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Due Date
          <Icons.sort className="ml-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const date = new Date(row.original.due_date as string)
      const formattedDate = Intl.DateTimeFormat("en-US", {
        weekday: "short",
        month: "long",
        day: "numeric",
        year: "numeric",
      }).format(date)
      return (
        <div className="px-4 py-2 text-right md:py-0 md:text-left">
          {formattedDate}
        </div>
      )
    },
  },
  {
    accessorKey: "due_amount",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Due Amount
          <Icons.sort className="ml-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const value = row.original.due_amount
      return (
        <div className="px-4 py-2 text-right md:py-0 md:text-left">
          ₱{value}
        </div>
      )
    },
  },
  {
    accessorKey: "total_amount",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Total Amount
          <Icons.sort className="ml-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const value = row.original.total_amount
      return (
        <div className="px-4 py-2 text-right md:py-0 md:text-left">
          ₱{value}
        </div>
      )
    },
  },
  {
    accessorKey: "actions",
    header: () => {
      return <Button variant="ghost">Actions</Button>
    },
    cell: ({ row }) => {
      return (
        <div className="flex gap-2 px-4 py-2 text-right md:py-0 md:text-left">
          <SubmitPayment row={row.original} />
          <ConsumerBillOperations data={row.original} />
        </div>
      )
    },
  },
]
