import type { ConsumersListType } from "@/types"
import { useQuery } from "@tanstack/react-query"
import { createFileRoute } from "@tanstack/react-router"

import { getAllConsumers } from "@/lib/api/billing"
import { DataTable } from "@/components/data-table"
import { Header } from "@/components/header"
import { announcementsColumns } from "@/components/table/announcements-columns"

export const Route = createFileRoute("/_home/sms-blast")({
  component: SMSBlastPage,
})

function SMSBlastPage() {
  const {
    data: consumers,
    isError,
    isLoading,
  } = useQuery<ConsumersListType[]>({
    queryKey: ["consumers"],
    queryFn: getAllConsumers,
  })

  return (
    <main className="space-y-4 p-4">
      <Header>Announcement</Header>
      <DataTable
        columns={announcementsColumns}
        data={consumers ?? []}
        search={{
          field: "name",
          label: "name",
        }}
        isLoading={isLoading}
        isError={isError}
        showSelectedRows
      />
    </main>
  )
}
