import type { PaymentDetailsType } from "@/types"
import { ColumnDef } from "@tanstack/react-table"

import { Button } from "@/components/ui/button"
import { Icons } from "@/components/icons"

export const paymentDetailsColumns: ColumnDef<PaymentDetailsType>[] = [
  {
    accessorKey: "consumer_name",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Consumer
          <Icons.sort className="ml-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const value = row.original.consumer_name
      return (
        <div className="px-4 py-2 text-right md:py-0 md:text-left">{value}</div>
      )
    },
  },
  {
    accessorKey: "consumer_address",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Address
          <Icons.sort className="ml-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const value = row.original.consumer_address
      return (
        <div className="px-4 py-2 text-right md:py-0 md:text-left">{value}</div>
      )
    },
  },
  {
    accessorKey: "payment_date",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Date
          <Icons.sort className="ml-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const date = new Date(row.original.payment_date as string)
      const formattedDate = Intl.DateTimeFormat("en-US", {
        weekday: "short",
        month: "long",
        day: "numeric",
        year: "numeric",
      }).format(date)
      return (
        <div className="px-4 py-2 text-right md:py-0 md:text-left">
          {formattedDate}
        </div>
      )
    },
  },
  {
    accessorKey: "amount_paid",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Amount Paid
          <Icons.sort className="ml-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const value = row.original.amount_paid
      return (
        <div className="px-4 py-2 text-right md:py-0 md:text-left">
          ₱{value}
        </div>
      )
    },
  },
]
