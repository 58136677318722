import * as React from "react"
import { CartesianGrid, Line, LineChart, XAxis, YAxis } from "recharts"

import { chartConfig } from "@/config/charts"
import { cn } from "@/lib/utils"
import {
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart"

interface LineChartProps {
  data: any
  toggledKeys?: string[]
  className?: string
  xAxisKey?: string
}

export function LineChartComponent({
  data,
  toggledKeys = [],
  className,
  xAxisKey = "time",
}: LineChartProps) {
  const [activeSeries, setActiveSeries] = React.useState<Array<string>>([])

  const handleLegendClick = (dataKey: string) => {
    setActiveSeries((prev) =>
      prev.includes(dataKey)
        ? prev.filter((el) => el !== dataKey)
        : [...prev, dataKey],
    )
  }

  React.useEffect(() => {
    setActiveSeries((prev) => {
      const newKeys = toggledKeys.filter((key) => !prev.includes(key))

      if (newKeys.length > 0) {
        return [...prev, ...newKeys]
      }

      return prev
    })
  }, [toggledKeys])

  const seriesKeys =
    data && data.length > 0
      ? Object.keys(data[0]).filter((key) => key !== xAxisKey)
      : []

  return (
    <div>
      {!data ? (
        // Loading state
        <div
          className={cn(
            "flex h-[345px] w-full items-center justify-center",
            className,
          )}
        >
          <span className="text-muted-foreground">Loading...</span>
        </div>
      ) : data.length === 0 ? (
        // No data state
        <div
          className={cn(
            "flex h-[345px] w-full items-center justify-center",
            className,
          )}
        >
          <span className="text-muted-foreground">No data available</span>
        </div>
      ) : (
        // Chart rendering when data is available
        <ChartContainer
          config={chartConfig}
          className={cn("aspect-auto h-[345px] w-full", className)}
        >
          <LineChart accessibilityLayer data={data}>
            <YAxis />
            <XAxis
              dataKey={xAxisKey}
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              minTickGap={32}
              tickFormatter={(value) => {
                const date = new Date(value)
                return date.toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                })
              }}
            />
            <CartesianGrid vertical={false} />
            <ChartTooltip
              content={
                <ChartTooltipContent
                  labelFormatter={(value) => {
                    return new Date(value).toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                    })
                  }}
                  indicator="dot"
                />
              }
            />
            <ChartLegend
              onClick={(entry: any) => handleLegendClick(entry.dataKey)}
              content={<ChartLegendContent />}
            />
            {seriesKeys.map((key) => {
              const color =
                chartConfig[key as keyof typeof chartConfig]?.color || "#000"
              return (
                <Line
                  key={key}
                  hide={activeSeries.includes(key)}
                  type="monotone"
                  dataKey={key}
                  dot={false}
                  stroke={color}
                  fill={color}
                />
              )
            })}
          </LineChart>
        </ChartContainer>
      )}
    </div>
  )
}
