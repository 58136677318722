"use client"

import { useState } from "react"
import type { BillingInfoType, ConsumerBillType } from "@/types"
import { zodResolver } from "@hookform/resolvers/zod"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useForm } from "react-hook-form"
import * as z from "zod"

import { payBill } from "@/lib/api/billing"
import { useToast } from "@/hooks/use-toast"
import { Button } from "@/components/ui/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"

import { Icons } from "../icons"

const formSchema = z.object({
  amount: z.coerce
    .number()
    .gte(1, { message: "At least ₱1" })
    .lte(100000, { message: "At most ₱100,000" }),
})

export function SubmitPayment({ row }: { row: ConsumerBillType }) {
  const [isConfirming, setIsConfirming] = useState(false)
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      amount: 0,
    },
  })

  const mutation = useMutation({
    mutationFn: async (data: BillingInfoType) => {
      await payBill(data)
    },
    onSuccess: () => {
      toast({
        description: "Payment processed successfully.",
      })
      queryClient.invalidateQueries({ queryKey: ["consumerBill"] })
    },
    onError: () => {
      toast({
        title: "Something went wrong!",
        description: "Please try again.",
        variant: "destructive",
      })
    },
  })

  function onSubmit(values: z.infer<typeof formSchema>) {
    mutation.mutate({
      amount_paid: values.amount,
      account_num: Number(row.acc_id),
      billing_id: row.billing_id,
    })
    setIsConfirming(false)
    form.reset()
  }

  function handleInitialSubmit() {
    setIsConfirming(true)
  }

  function handleCancel() {
    setIsConfirming(false)
  }

  function handleKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Enter") {
      event.preventDefault()
      if (!isConfirming) {
        handleInitialSubmit()
      } else {
        form.handleSubmit(onSubmit)()
      }
    }
  }

  return (
    <Form {...form}>
      <form
        noValidate
        className="flex gap-1"
        onSubmit={form.handleSubmit(onSubmit)}
        onKeyDown={handleKeyDown}
      >
        <FormField
          control={form.control}
          name="amount"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  className="h-8 max-w-[100px]"
                  type="number"
                  placeholder="₱"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {!isConfirming ? (
          <Button className="h-8" type="button" onClick={handleInitialSubmit}>
            Pay
          </Button>
        ) : (
          <div className="flex gap-1">
            <Button
              className="size-8"
              type="submit"
              variant="default"
              size="icon"
            >
              <Icons.checkCircle className="size-4" />
            </Button>
            <Button
              className="size-8"
              type="button"
              onClick={handleCancel}
              variant="outline"
              size="icon"
            >
              <Icons.delete className="size-4" />
            </Button>
          </div>
        )}
      </form>
    </Form>
  )
}
