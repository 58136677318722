import * as React from "react"
import type { MeterInfoType } from "@/types"
import { zodResolver } from "@hookform/resolvers/zod"
import { useMutation, useQueries, useQueryClient } from "@tanstack/react-query"
import { useNavigate } from "@tanstack/react-router"
import { format } from "date-fns"
import { useForm } from "react-hook-form"
import * as z from "zod"

import {
  addMeter,
  editMeter,
  getConnectionType,
  getMeterSize,
  getMeterStatus,
} from "@/lib/api/billing"
import { meterSchema as formSchema } from "@/lib/schema/meter"
import { cn } from "@/lib/utils"
import { useToast } from "@/hooks/use-toast"
import { Button } from "@/components/ui/button"
import { Calendar } from "@/components/ui/calendar"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"

import { ComboboxSearch } from "../combobox-search"
import { Icons } from "../icons"

export function MeterForm({
  editData,
  setModal,
}: {
  editData?: MeterInfoType
  setModal?: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const { toast } = useToast()
  const queryClient = useQueryClient()
  const [connectionTypeId, setConnectionTypeId] = React.useState<
    number | undefined
  >(1)
  const navigate = useNavigate()

  const queryConfigs = [
    { queryKey: ["connectionType"], queryFn: getConnectionType },
    {
      queryKey: ["meterStatus"],
      queryFn: getMeterStatus,
    },
    {
      queryKey: ["meterSize", connectionTypeId],
      queryFn: () => getMeterSize(connectionTypeId!),
    },
  ]

  const results = useQueries({
    queries: queryConfigs,
  })

  const [connectionType, meterStatus, meterSize] = results.map(
    (result) => result.data || [],
  )

  const editFormSchema = formSchema.extend({
    purchase_date: z.union([
      z.date().optional().nullable(),
      z.string().optional().nullable(),
    ]),
    decommission_date: z.union([
      z.date().optional().nullable(),
      z.string().optional().nullable(),
    ]),
  })

  const isEditMode = Boolean(editData)

  type FormValues = z.infer<typeof formSchema> | z.infer<typeof editFormSchema>

  const form = useForm<FormValues>({
    resolver: zodResolver(isEditMode ? editFormSchema : formSchema),
    defaultValues: isEditMode
      ? {
          serial_number: editData?.serial_number || "",
          purchase_date: editData?.purchase_date,
          decommission_date: editData?.decommission_date,
          meter_status_id: editData?.meter_status_id || 0,
          meter_size_id: editData?.meter_size_id || 0,
          connection_type_id: editData?.connection_type_id || 0,
          brand: editData?.brand || "",
          model: editData?.model || "",
        }
      : {
          serial_number: "",
          meter_status_id: 0,
          meter_size_id: 0,
          connection_type_id: 0,
          brand: "",
          model: "",
        },
  })

  const mutation = useMutation({
    mutationFn: async ({
      id,
      values,
    }: {
      id?: number
      values: z.infer<typeof formSchema>
    }) => {
      if (id) {
        await editMeter(id, values)
      } else {
        await addMeter(values)
      }
    },
    onSuccess: () => {
      toast({
        description: editData
          ? "Meter updated successfully."
          : "Meter added successfully.",
      })

      const invalidateQueryKeys = [
        ["meterInfo"],
        ["meterInfoAnalytics"],
        ["activeMeter"],
        ["inactiveMeter"],
        ["decommissionedMeter"],
        ["unassignedMeter"],
      ]

      invalidateQueryKeys.forEach((key) => {
        queryClient.invalidateQueries({ queryKey: key })
      })

      if (setModal) {
        setModal(false)
      } else {
        navigate({ to: "/meter-info" })
      }
    },
    onError: () => {
      toast({
        title: "Something went wrong!",
        description: "Try a different serial number.",
        variant: "destructive",
      })
      queryClient.invalidateQueries({ queryKey: ["meterInfo"] })
    },
  })

  function onSubmit(values: z.infer<typeof formSchema>) {
    mutation.mutate({ id: editData?.meter_info_id as number, values })
  }

  return (
    <Form {...form}>
      <form
        noValidate
        onSubmit={form.handleSubmit(onSubmit)}
        className="max-h-[500px] space-y-4 overflow-y-auto px-1"
      >
        <FormField
          control={form.control}
          name="serial_number"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                Serial Number <span className="text-destructive">*</span>
              </FormLabel>
              <FormControl>
                <Input placeholder="Serial Number" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {isEditMode && (
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <FormField
              control={form.control}
              name="purchase_date"
              render={({ field }) => (
                <FormItem className="flex flex-col justify-center">
                  <FormLabel className="py-1">Purchase Date</FormLabel>
                  <Popover modal>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant={"outline"}
                          className={cn(
                            "pl-3 text-left font-normal",
                            !field.value && "text-muted-foreground",
                          )}
                        >
                          {field.value ? (
                            format(field.value, "PPP")
                          ) : (
                            <span>Pick a date</span>
                          )}
                          <Icons.calendar className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        selected={
                          field.value ? new Date(field.value) : undefined
                        }
                        onSelect={field.onChange}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="decommission_date"
              render={({ field }) => (
                <FormItem className="flex flex-col justify-center">
                  <FormLabel className="py-1">Decommission Date</FormLabel>
                  <Popover modal>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant={"outline"}
                          className={cn(
                            "pl-3 text-left font-normal",
                            !field.value && "text-muted-foreground",
                          )}
                        >
                          {field.value ? (
                            format(field.value, "PPP")
                          ) : (
                            <span>Pick a date</span>
                          )}
                          <Icons.calendar className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        selected={
                          field.value ? new Date(field.value) : undefined
                        }
                        onSelect={field.onChange}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        )}
        <FormField
          control={form.control}
          name="meter_status_id"
          render={({ field }) => (
            <FormItem className="flex flex-col justify-center">
              <FormLabel className="py-1">
                Meter Status <span className="text-destructive">*</span>
              </FormLabel>
              <FormControl>
                <ComboboxSearch
                  modal
                  field={field}
                  options={meterStatus}
                  placeholder="Select meter status"
                  idAsValue
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <FormField
            control={form.control}
            name="connection_type_id"
            render={({ field }) => (
              <FormItem className="flex flex-col justify-center">
                <FormLabel className="py-1">
                  Connection Type <span className="text-destructive">*</span>
                </FormLabel>
                <FormControl>
                  <ComboboxSearch
                    modal
                    field={field}
                    options={connectionType}
                    placeholder="Select connection type"
                    onSelectId={(id?: string | number) =>
                      setConnectionTypeId(id as number)
                    }
                    idAsValue
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="meter_size_id"
            render={({ field }) => (
              <FormItem className="flex flex-col justify-center">
                <FormLabel className="py-1">
                  Meter Size <span className="text-destructive">*</span>
                </FormLabel>
                <FormControl>
                  <ComboboxSearch
                    modal
                    field={field}
                    options={meterSize}
                    placeholder="Select meter size"
                    idAsValue
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <FormField
            control={form.control}
            name="brand"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Brand <span className="text-destructive">*</span>
                </FormLabel>
                <FormControl>
                  <Input placeholder="Brand" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="model"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Model <span className="text-destructive">*</span>
                </FormLabel>
                <FormControl>
                  <Input placeholder="Model" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex justify-end">
          <Button
            disabled={mutation.isPending}
            type="submit"
            className="w-full md:w-auto"
          >
            {mutation.isPending && (
              <Icons.loader className="mr-2 animate-spin" />
            )}
            <span>{editData ? "Update Meter" : "Add Meter"}</span>
          </Button>
        </div>
      </form>
    </Form>
  )
}
