import { createFileRoute } from "@tanstack/react-router"

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { ChangePasswordForm } from "@/components/forms/edit-password-form"
import { Header } from "@/components/header"

export const Route = createFileRoute("/_home/settings/change-password")({
  component: ProfilePage,
})

function ProfilePage() {
  return (
    <main className="space-y-4 p-4">
      <Header
        subroutes={[
          {
            route: "#",
            label: "Settings",
          },
        ]}
      >
        Change Password
      </Header>
      <Card className="mx-auto max-w-[550px]">
        <CardHeader>
          <CardTitle>Change Password</CardTitle>
          <CardDescription>Update your password.</CardDescription>
        </CardHeader>
        <CardContent>
          <ChangePasswordForm />
        </CardContent>
      </Card>
    </main>
  )
}
