import { useState } from "react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useMutation } from "@tanstack/react-query"
import { useNavigate } from "@tanstack/react-router"
import Cookies from "js-cookie"
import { useForm } from "react-hook-form"
import { z } from "zod"

import { systemConfig } from "@/config/site"
import {
  authenticate,
  deviceLogin,
  disableUser,
  sendMfaOtp,
} from "@/lib/api/auth"
import { getUserEmail } from "@/lib/auth"
import { loginSchema } from "@/lib/schema/login"
import { useToast } from "@/hooks/use-toast"
import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Icons } from "@/components/icons"

export function LoginForm() {
  const { toast } = useToast()
  const navigate = useNavigate({ from: "/login" })
  const [loginError, setLoginError] = useState<string | null>(null)
  const form = useForm<z.infer<typeof loginSchema>>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      username: "",
      password: "",
      system_id: systemConfig.hirayaSystemId,
    },
  })

  const loginAttempts = Cookies.get("loginAttempts")
    ? parseInt(Cookies.get("loginAttempts") || "0")
    : 0

  const nextAttemptTime = Cookies.get("nextAttemptTime")
    ? parseInt(Cookies.get("nextAttemptTime") || "0")
    : 0

  const mutation = useMutation({
    mutationFn: async (data: z.infer<typeof loginSchema>) => {
      await authenticate(data)
    },
    onSuccess: async () => {
      try {
        const response = await deviceLogin()
        const email = getUserEmail()

        // Reset cookies upon successful login
        Cookies.remove("loginAttempts")
        Cookies.remove("nextAttemptTime")
        setLoginError(null) // Clear any existing errors

        if (response.ok) {
          const mfaAuth = Cookies.get(`mfa-auth-${email}`)
          if (mfaAuth === "true") {
            navigate({ to: "/" })
          } else {
            sendMfaOtp()
            navigate({ to: "/mfa-auth" })
          }
        } else {
          navigate({ to: "/unknown-device" })
        }
      } catch (error) {
        navigate({ to: "/unknown-device" })
      }
    },
    onError: (error) => {
      toast({
        title: "Something went wrong!",
        description: error.message,
        variant: "destructive",
      })
      if (loginAttempts >= 4) {
        setLoginError("Your account has been disabled. Please contact support.")
        disableUser(form.getValues().username) // Disable user on the 5th failed attempt

        // Reset login attempts after disabling account
        Cookies.remove("loginAttempts")
        Cookies.remove("nextAttemptTime")
      } else {
        let errorMsg = "Login failed. Please try again."
        let nextLockTime = 0

        if (loginAttempts >= 2) {
          nextLockTime = Date.now() + 5 * 60 * 1000 // 5 mins
          Cookies.set("nextAttemptTime", nextLockTime.toString())
          errorMsg =
            "Too many attempts! Please wait 5 minutes before trying again."
        } else if (loginAttempts >= 3) {
          nextLockTime = Date.now() + 10 * 60 * 1000 // 10 mins
          Cookies.set("nextAttemptTime", nextLockTime.toString())
          errorMsg =
            "Too many attempts! Please wait 10 minutes before trying again."
        }

        // Increment failed attempts and store in cookies
        Cookies.set("loginAttempts", (loginAttempts + 1).toString())
        setLoginError(errorMsg)
      }
    },
  })

  async function onSubmit(values: z.infer<typeof loginSchema>) {
    if (nextAttemptTime && Date.now() < nextAttemptTime) {
      const waitTime = Math.ceil((nextAttemptTime - Date.now()) / 1000 / 60)
      setLoginError(
        `Please wait ${waitTime} more minute(s) before trying again.`,
      )
      return
    }

    mutation.mutate(values)
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex w-[20rem] flex-col gap-4"
      >
        <FormField
          control={form.control}
          name="username"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  placeholder="Username"
                  className="rounded-full bg-white text-black"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  type="password"
                  placeholder="Password"
                  className="rounded-full bg-white text-black"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="items-top flex space-x-2">
          <Checkbox id="rememberMe" />
          <div className="grid gap-1.5 leading-none">
            <label
              htmlFor="rememberMe"
              className="text-sm font-medium leading-none text-black peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              Remember Me
            </label>
          </div>
        </div>
        <Button
          type="submit"
          disabled={mutation.isPending}
          className="rounded-full"
        >
          {mutation.isPending && <Icons.loader className="mr-2 animate-spin" />}
          <span>Login</span>
        </Button>
        {loginError && (
          <p className="mt-2 text-center text-destructive">{loginError}</p>
        )}
      </form>
    </Form>
  )
}
