import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"
import { v4 as uuidv4 } from "uuid"

import { env } from "@/env"

import { refreshAccessToken } from "./api/auth"
import { getAuthToken, getRefreshToken, getUserEmail } from "./auth"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function formatDate(input: string | number | Date): string {
  const date = input instanceof Date ? input : new Date(input)
  return date.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  })
}

export function dateRangeParams(searchParams: { from: string; to: string }) {
  if (
    !searchParams.from ||
    isNaN(Date.parse(searchParams.from)) ||
    !searchParams.to ||
    isNaN(Date.parse(searchParams.to))
  ) {
    const dateRange = {
      from: new Date(),
      to: new Date(),
    }
    const oneYearAgo = new Date()
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1)
    dateRange.from = oneYearAgo

    return dateRange
  }

  return {
    from: new Date(searchParams.from),
    to: new Date(searchParams.to),
  }
}

export function findByProperty<ItemType, KeyType extends keyof ItemType>(
  array: ItemType[],
  property: KeyType,
  value: ItemType[KeyType],
): ItemType | undefined {
  return array.find((item) => item[property] === value)
}

export function normalizeCamelCase(str: string) {
  return str.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
    return str.toUpperCase()
  })
}

export function normalizeKebabCase(str: string): string {
  return str
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")
}

export async function apiFetch(
  endpoint: string,
  options: RequestInit = {},
  skipAuth: boolean = false,
): Promise<Response> {
  let token = !skipAuth ? getAuthToken() : null

  const headers = new Headers(options.headers || {})
  headers.set("Content-Type", "application/json")
  headers.set("Accept", "application/json")

  if (token) {
    headers.set("Authorization", `Bearer ${token}`)
  }

  const method = options.method || "GET"

  let response = await fetch(`${env.API_URL}${endpoint}`, {
    ...options,
    method,
    headers,
  })

  if (response.status === 401 && !skipAuth) {
    const refreshToken = getRefreshToken()
    if (refreshToken) {
      const data = await refreshAccessToken(refreshToken)
      token = data.access

      if (token) {
        headers.set("Authorization", `Bearer ${token}`)
        response = await fetch(`${env.API_URL}${endpoint}`, {
          ...options,
          method,
          headers,
        })
      }
    }
  }

  return response
}

export function getDeviceId(): string {
  const email = getUserEmail()
  let deviceId = localStorage.getItem(`device_id-${email}`)
  if (!deviceId) {
    deviceId = uuidv4()
    localStorage.setItem(`device_id-${email}`, deviceId)
  }
  return deviceId
}

export async function getIpAddress(): Promise<string> {
  const response = await fetch("https://api.ipify.org?format=json")
  if (!response.ok) {
    throw new Error("Failed to fetch IP address")
  }
  const data = await response.json()
  return data.ip
}

export function getBrowserAndOs(): { browser: string; os: string } {
  const userAgent = navigator.userAgent
  let browser = "Unknown"
  let os = "Unknown"

  if (userAgent.includes("Chrome")) browser = "Chrome"
  else if (userAgent.includes("Firefox")) browser = "Firefox"
  else if (userAgent.includes("Safari") && !userAgent.includes("Chrome"))
    browser = "Safari"

  if (userAgent.includes("Windows")) os = "Windows"
  else if (userAgent.includes("Mac")) os = "Mac OS"
  else if (userAgent.includes("Linux")) os = "Linux"
  else if (userAgent.includes("Android")) os = "Android"
  else if (userAgent.includes("iPhone")) os = "iOS"

  return { browser, os }
}
