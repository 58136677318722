import Cookies from "js-cookie"

import { systemConfig } from "@/config/site"
import { IconKeys } from "@/components/icons"

import { apiFetch } from "../utils"

export type NavLink = {
  id: number
  menu_route: string
  description: string
  name: string
  icon: IconKeys
  show: boolean
  isActive?: boolean
  sub_menu?: Array<
    Omit<NavLink, "sub_menu" | "menu_route" | "description" | "isActive"> & {
      sub_menu_route: string
    }
  >
}

export async function fetchMenuTabs(): Promise<NavLink[]> {
  const client = Cookies.get("user_client_info") || "{}"
  const clientId = JSON.parse(client).client_id

  const res = await apiFetch("menu-tabs/", {
    method: "POST",
    body: JSON.stringify({
      client: clientId,
      system: systemConfig.hirayaSystemId,
    }),
  })

  if (!res.ok) {
    throw new Error("Failed to fetch menu tabs")
  }

  const menuTabs: NavLink[] = await res.json()

  localStorage.setItem("menuTabs", JSON.stringify(menuTabs))

  return menuTabs
}
