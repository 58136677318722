import * as React from "react"
import type { MeterInfoAnalyticsType, MeterInfoType } from "@/types"
import { useQueries, useQuery } from "@tanstack/react-query"
import { createFileRoute, Link } from "@tanstack/react-router"
import type { DateRange } from "react-day-picker"

import {
  getMeterInfo,
  getMeterInfoAnalytics,
  getTotalActiveMeter,
  getTotalDecommissionedMeter,
  getTotalInactiveMeter,
  getTotalUnassignedMeter,
} from "@/lib/api/billing"
import { buttonVariants } from "@/components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { BarChartComponent } from "@/components/charts/barchart-horizontal"
import { DataTable } from "@/components/data-table"
import { DateRangePicker } from "@/components/date-range-picker"
import { Header } from "@/components/header"
import { Icons } from "@/components/icons"
import { meterInfoColumns } from "@/components/table/meter-info-columns"

export const Route = createFileRoute("/_home/meter-info/")({
  component: MeterInfoPage,
})

function MeterInfoPage() {
  const [date, setDate] = React.useState<DateRange>({
    from: new Date(new Date().setMonth(new Date().getMonth() - 1)),
    to: new Date(),
  })

  const { data, isError, isLoading } = useQuery<MeterInfoType[]>({
    queryKey: ["meterInfo"],
    queryFn: getMeterInfo,
  })

  const { data: meterInfoAnalytics } = useQuery<MeterInfoAnalyticsType[]>({
    queryKey: ["meterInfoAnalytics", date],
    queryFn: async () => {
      const from = date.from ?? new Date()
      const to = date.to ?? new Date()
      return await getMeterInfoAnalytics(from.toISOString(), to.toISOString())
    },
  })

  const queryConfigs = [
    { queryKey: ["activeMeter"], queryFn: getTotalActiveMeter },
    { queryKey: ["inactiveMeter"], queryFn: getTotalInactiveMeter },
    { queryKey: ["decommissionedMeter"], queryFn: getTotalDecommissionedMeter },
    { queryKey: ["unassignedMeter"], queryFn: getTotalUnassignedMeter },
  ]

  const meterCardsData = useQueries({
    queries: queryConfigs,
  })

  const [activeMeter, inactiveMeter, decommissionedMeter, unassignedMeter] =
    meterCardsData.map((result) => result.data ?? {}) as [
      Awaited<ReturnType<typeof getTotalActiveMeter>>,
      Awaited<ReturnType<typeof getTotalInactiveMeter>>,
      Awaited<ReturnType<typeof getTotalDecommissionedMeter>>,
      Awaited<ReturnType<typeof getTotalUnassignedMeter>>,
    ]

  return (
    <main className="space-y-4 p-4">
      <Header>Meter Information</Header>

      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Total Active</CardTitle>
            <Icons.gauge className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent className="text-2xl font-bold">
            {activeMeter.total_active_meters || 0}
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">
              Total Inactive
            </CardTitle>
            <Icons.gauge className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent className="text-2xl font-bold">
            {inactiveMeter.total_inactive_meters || 0}
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">
              Total Decommissioned
            </CardTitle>
            <Icons.gauge className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent className="text-2xl font-bold">
            {decommissionedMeter.total_decommissioned_meters || 0}
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">
              Total Unassigned
            </CardTitle>
            <Icons.gauge className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent className="text-2xl font-bold">
            {unassignedMeter.total_unassigned_meters || 0}
          </CardContent>
        </Card>
      </div>
      <Card>
        <CardHeader className="flex items-center justify-between gap-2 md:flex-row">
          <CardTitle>Meter Analytics</CardTitle>
          <DateRangePicker date={date} setDate={setDate} />
        </CardHeader>
        <CardContent className="pl-2 pr-10">
          <BarChartComponent
            data={meterInfoAnalytics}
            xAxisKey="key"
            className="h-[240px]"
          />
        </CardContent>
      </Card>
      <DataTable
        columns={meterInfoColumns}
        data={data ?? []}
        search={{
          field: "serial_number",
          label: "serial number",
        }}
        isLoading={isLoading}
        isError={isError}
      >
        <div className="flex w-full justify-end">
          <Link
            to="/meter-info/add"
            className={buttonVariants({ variant: "default" })}
          >
            Add Meter
          </Link>
        </div>
      </DataTable>
    </main>
  )
}
