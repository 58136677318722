import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import {
  createRootRoute,
  Outlet,
  ScrollRestoration,
} from "@tanstack/react-router"

import { Toaster } from "@/components/ui/toaster"
import { TooltipProvider } from "@/components/ui/tooltip"
import { ThemeProvider } from "@/components/providers/theme-provider"

const queryClient = new QueryClient()

export const Route = createRootRoute({
  component: () => (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
        <TooltipProvider>
          <div
            vaul-drawer-wrapper=""
            className="overflow-x-hidden bg-background"
          >
            <ScrollRestoration />
            <Outlet />
          </div>
          <Toaster />
        </TooltipProvider>
      </ThemeProvider>
    </QueryClientProvider>
  ),
})
