import Cookies from "js-cookie"

import { systemConfig } from "@/config/site"

import { getClientId } from "../auth"
import { apiFetch } from "../utils"
import type { NavLink } from "./menu"

interface ActivityLogData {
  menu_path: string
  sub_menu_path: string
  action: string
  status_code: number
  api_endpoint: string
  web_url: string
}

export async function postActivityLog(data: ActivityLogData) {
  const {
    menu_path,
    sub_menu_path,
    action,
    status_code,
    api_endpoint,
    web_url,
  } = data

  const storedMenuTabs = localStorage.getItem("menuTabs")
  if (!storedMenuTabs) {
    console.warn("No menu tabs found in localStorage")
    return
  }

  const menuTabs: NavLink[] = JSON.parse(storedMenuTabs)

  let client_menu_tab_id: number | null = null
  let client_sub_menu_tab_id: number | null = null

  const matchedTab = menuTabs.find((tab) => tab.menu_route === menu_path)

  if (matchedTab) {
    client_menu_tab_id = matchedTab.id

    if (matchedTab?.sub_menu) {
      const matchedSubMenu = matchedTab.sub_menu.find(
        (sub) => sub.sub_menu_route === sub_menu_path,
      )
      if (matchedSubMenu) {
        client_sub_menu_tab_id = matchedSubMenu.id
      }
    }
  } else {
    console.warn(`No matching menu tab found for route: ${menu_path}`)
  }

  const clientId = getClientId()
  const userId = Cookies.get("user_id")

  const payload = {
    user_id: userId,
    client_id: clientId,
    hirayatech_system_id: systemConfig.hirayaSystemId,
    client_menu_tab_id,
    client_sub_menu_tab_id,
    action,
    status_code,
    api_endpoint,
    web_url,
  }

  try {
    const response = await apiFetch("save-activity-logs/", {
      method: "POST",
      body: JSON.stringify(payload),
    })

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    const result = await response.json()
    console.log("Logs: ", result)
    return result
  } catch (error) {
    console.error("Logs: ", error)
    throw error
  }
}
