import * as React from "react"
import type { ConsumerConnectionBillingStatusType } from "@/types"
import Cookies from "js-cookie"

import { PositionProvider } from "@/components/providers/position-provider"

import { Map } from "./map"
import { MapPicker } from "./picker/map-picker"

type Coordinates = [number, number]

interface MapRendererProps {
  picker?: { lat: number; lng: number }
  onPositionChange?: (position: [number, number]) => void
  markers?: ConsumerConnectionBillingStatusType[]
}

export function MapRenderer({
  picker,
  onPositionChange,
  markers,
}: MapRendererProps) {
  const [currentLocation, setCurrentLocation] =
    React.useState<Coordinates | null>(null)
  const [locationObtained, setLocationObtained] = React.useState(false)

  React.useEffect(() => {
    const fetchLocation = () => {
      const userClientInfo = Cookies.get("user_client_info")
      if (userClientInfo) {
        const { lat, lon } = JSON.parse(userClientInfo)
        setCurrentLocation([lat, lon])
      } else {
        setCurrentLocation([14.5995, 120.9842])
      }
      setLocationObtained(true)
    }

    fetchLocation()
  }, [])

  React.useEffect(() => {
    let lastClickTime = 0
    const doubleClickThreshold = 300

    const handleMouseDown = (event: MouseEvent) => {
      if (event.button === 1) {
        const currentTime = new Date().getTime()
        if (currentTime - lastClickTime < doubleClickThreshold) {
          window.location.reload()
        }
        lastClickTime = currentTime
      }
    }

    window.addEventListener("mousedown", handleMouseDown)

    return () => {
      window.removeEventListener("mousedown", handleMouseDown)
    }
  }, [])

  const renderMap = () => {
    if (!locationObtained) {
      return (
        <div className="flex h-screen items-center justify-center">
          <p>Loading map...</p>
        </div>
      )
    }

    return picker ? (
      <MapPicker position={[picker.lat, picker.lng]} />
    ) : (
      <Map position={currentLocation!} markers={markers} />
    )
  }

  return (
    <div>
      {onPositionChange ? (
        <PositionProvider onPositionChange={onPositionChange}>
          {renderMap()}
        </PositionProvider>
      ) : (
        renderMap()
      )}
    </div>
  )
}
