import { createFileRoute } from "@tanstack/react-router"

import { ProfileCard } from "@/components/dashboard/profile/profile-card"
import { Header } from "@/components/header"

export const Route = createFileRoute("/_home/settings/profile")({
  component: ProfilePage,
})

function ProfilePage() {
  return (
    <main className="space-y-4 p-4">
      <Header
        subroutes={[
          {
            route: "#",
            label: "Settings",
          },
        ]}
      >
        Profile
      </Header>
      <ProfileCard />
    </main>
  )
}
