import * as React from "react"
import type { ConsumerDetailsType } from "@/types"
import { zodResolver } from "@hookform/resolvers/zod"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Link } from "@tanstack/react-router"
import { useForm } from "react-hook-form"
import * as z from "zod"

import { deleteConsumer, generatePdfBill } from "@/lib/api/billing"
import { useToast } from "@/hooks/use-toast"
import { Button } from "@/components/ui/button"
import {
  Credenza,
  CredenzaBody,
  CredenzaClose,
  CredenzaContent,
  CredenzaDescription,
  CredenzaFooter,
  CredenzaHeader,
  CredenzaTitle,
} from "@/components/ui/credenza"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Icons } from "@/components/icons"

interface DataProps {
  data: ConsumerDetailsType
}

export function ConsumerDetailsOperations({ data }: DataProps) {
  const [showDropDown, setShowDropDown] = React.useState<boolean>(false)
  const [showBillModal, setShowBillModal] = React.useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false)

  return (
    <div>
      <DropdownMenu open={showDropDown} onOpenChange={setShowDropDown}>
        <DropdownMenuTrigger>
          <div className="flex h-8 w-8 items-center justify-center rounded-md border transition-colors hover:bg-muted">
            <Icons.ellipsis className="size-4" />
            <span className="sr-only">Open</span>
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem asChild>
            <Link to={`/manage-users/view/${data.consumer_id}`}>
              <Icons.view className="mr-2 size-4" />
              View Consumer
            </Link>
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem asChild>
            <Link to={`/manage-users/edit/${data.consumer_id}`}>
              <Icons.edit className="mr-2 size-4" />
              Edit Consumer
            </Link>
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            onSelect={() => {
              setShowBillModal(true)
              setShowDropDown(false)
            }}
          >
            <Icons.add className="mr-2 size-4" />
            Generate Bill
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            className="text-destructive"
            onSelect={() => {
              setShowDeleteModal(true)
              setShowDropDown(false)
            }}
          >
            <Icons.delete className="mr-2 size-4" />
            Delete Consumer
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <GenerateBillModal
        accId={data.acc_id}
        showModal={showBillModal}
        setShowModal={setShowBillModal}
      />
      <DeleteConsumerModal
        ConsumerId={data.consumer_id}
        ConsumerName={data.name}
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
      />
    </div>
  )
}

function GenerateBillModal({
  accId,
  showModal,
  setShowModal,
}: {
  accId: string | number
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const queryClient = useQueryClient()
  const { toast } = useToast()

  const formSchema = z.object({
    account_num: z.string().min(1, "Account number is required"),
    current_consumption: z.coerce
      .number()
      .min(1, "Current consumption is required"),
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      account_num: accId.toString(),
      current_consumption: 0,
    },
  })

  const mutation = useMutation({
    mutationFn: async (values: z.infer<typeof formSchema>) => {
      const payload: z.infer<typeof formSchema> = {
        account_num: accId.toString(),
        current_consumption: values.current_consumption,
      }
      await generatePdfBill(payload)
    },
    onSuccess: () => {
      toast({
        description: "Bill generated successfully.",
      })
      queryClient.invalidateQueries({ queryKey: ["consumerBill"] })
      setShowModal(false)
    },
    onError: (error: unknown) => {
      toast({
        title: "Something went wrong!",
        description: `${error}`,
        variant: "destructive",
      })
    },
  })

  function onSubmit(values: z.infer<typeof formSchema>) {
    mutation.mutate(values)
  }

  return (
    <>
      <Credenza open={showModal} onOpenChange={setShowModal}>
        <CredenzaContent>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
              <CredenzaHeader>
                <CredenzaTitle>Generate Bill</CredenzaTitle>
                <CredenzaDescription>
                  Generate a bill for the selected consumer
                </CredenzaDescription>
              </CredenzaHeader>
              <CredenzaBody>
                <FormField
                  control={form.control}
                  name="current_consumption"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Unit</FormLabel>
                      <FormControl>
                        <Input type="number" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </CredenzaBody>
              <CredenzaFooter>
                <CredenzaClose asChild>
                  <Button variant={"outline"}>Cancel</Button>
                </CredenzaClose>
                <Button disabled={mutation.isPending} type="submit">
                  {mutation.isPending && (
                    <Icons.loader className="mr-2 animate-spin" />
                  )}
                  Submit
                </Button>
              </CredenzaFooter>
            </form>
          </Form>
        </CredenzaContent>
      </Credenza>
    </>
  )
}

function DeleteConsumerModal({
  ConsumerId,
  ConsumerName,
  showModal,
  setShowModal,
}: {
  ConsumerId: number
  ConsumerName: string
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const queryClient = useQueryClient()
  const { toast } = useToast()

  const formSchema = z.object({
    id: z.coerce.number(),
    fullName: z
      .string()
      .min(1, "Required")
      .refine((val) => val === ConsumerName, {
        message: "Name must match the Consumer name.",
      }),
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      id: ConsumerId,
      fullName: "",
    },
  })

  const mutation = useMutation({
    mutationFn: async () => {
      await deleteConsumer(ConsumerId)
    },
    onSuccess: () => {
      toast({
        description: "Consumer deleted successfully.",
      })
      queryClient.invalidateQueries({ queryKey: ["consumerDetails"] })
      setShowModal(false)
    },
    onError: (error: unknown) => {
      toast({
        title: "Something went wrong!",
        description: `${error}`,
        variant: "destructive",
      })
    },
  })

  async function onSubmit() {
    mutation.mutate()
  }

  return (
    <>
      <Credenza open={showModal} onOpenChange={setShowModal}>
        <CredenzaContent>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="space-y-4 pb-4"
            >
              <CredenzaHeader>
                <CredenzaTitle>Delete Consumer</CredenzaTitle>
                <CredenzaDescription>
                  You cannot undo this action!
                </CredenzaDescription>
              </CredenzaHeader>
              <CredenzaBody>
                <FormField
                  control={form.control}
                  name="fullName"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Type "{ConsumerName}" to delete.</FormLabel>
                      <FormControl>
                        <Input {...field} autoComplete="off" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </CredenzaBody>
              <CredenzaFooter>
                <CredenzaClose asChild>
                  <Button variant={"outline"}>Cancel</Button>
                </CredenzaClose>
                <Button
                  disabled={mutation.isPending}
                  type="submit"
                  variant={"destructive"}
                >
                  {mutation.isPending && (
                    <Icons.loader className="mr-2 animate-spin" />
                  )}
                  Delete
                </Button>
              </CredenzaFooter>
            </form>
          </Form>
        </CredenzaContent>
      </Credenza>
    </>
  )
}
