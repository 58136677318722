import "leaflet/dist/leaflet.css"
import "@/styles/leaflet-dark.css"
import "@/styles/leaflet-tooltip.css"

import { useState } from "react"
import { Marker } from "@adamscybot/react-leaflet-component-marker"
import { MapContainer, TileLayer, useMapEvents } from "react-leaflet"

import { Icons } from "@/components/icons"
import { usePosition } from "@/components/providers/position-provider"

interface MapProps {
  position: [number, number]
}

function DraggableMarker({
  initialPosition,
}: {
  initialPosition: [number, number]
}) {
  const { onPositionChange } = usePosition()
  const [position, setPosition] = useState<[number, number]>(initialPosition)

  useMapEvents({
    dragend: (event) => {
      const marker = event.target
      const { lat, lng } = marker.getLatLng()
      setPosition([lat, lng])
      onPositionChange([lat, lng])
    },
  })

  return (
    <Marker
      icon={<Icons.user className="fill-primary" />}
      position={position}
      draggable={true}
      eventHandlers={{
        dragend: (e) => {
          const marker = e.target
          const { lat, lng } = marker.getLatLng()
          onPositionChange([lat, lng])
        },
      }}
    />
  )
}

export function MapPicker({ position }: MapProps) {
  return (
    <div>
      <MapContainer
        className="h-screen w-full"
        center={position}
        zoom={15}
        minZoom={1}
        maxZoom={18}
        scrollWheelZoom={true}
        doubleClickZoom={false}
        attributionControl={false}
      >
        <TileLayer url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <DraggableMarker initialPosition={position} />
      </MapContainer>
    </div>
  )
}
