import { useState } from "react"
import type { UserInfoType } from "@/types"
import Cookies from "js-cookie"

import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader } from "@/components/ui/card"
import {
  Credenza,
  CredenzaBody,
  CredenzaContent,
  CredenzaDescription,
  CredenzaHeader,
  CredenzaTitle,
  CredenzaTrigger,
} from "@/components/ui/credenza"
import { Separator } from "@/components/ui/separator"
import { EditUserForm } from "@/components/forms/edit-user-form"
import { Icons } from "@/components/icons"

type SelectedUser = Pick<
  UserInfoType,
  "full_name" | "email" | "username" | "user_id" | "mobile_number" | "address"
>

export function ProfileCard() {
  const userInfo = JSON.parse(Cookies.get("user_info") as string) || {}
  const [user, setUser] = useState<SelectedUser>({
    user_id: userInfo.user_id || "",
    username: userInfo.username || "",
    full_name: userInfo.full_name || "",
    email: userInfo.email || "",
    address: userInfo.address || "",
    mobile_number: userInfo.mobile_number || "",
  })
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)

  return (
    <Card className="mx-auto w-full max-w-4xl overflow-hidden">
      <CardHeader className="p-0">
        <div className="relative h-32 bg-gradient-to-r from-primary to-primary">
          <div className="absolute bottom-0 left-0 w-full bg-gradient-to-t from-black/50 to-transparent p-6 text-white">
            <h2 className="text-3xl font-bold tracking-tight">
              {user.full_name}
            </h2>
            <div className="flex items-center">
              <span className="opacity-90">@{user.username}</span>
            </div>
          </div>
        </div>
      </CardHeader>
      <CardContent className="pt-6">
        <div className="grid gap-6 md:grid-cols-2">
          <InfoItem
            icon={<Icons.mail className="size-5" />}
            label="Email"
            value={user.email}
          />
          <InfoItem
            icon={<Icons.user className="size-5" />}
            label="User ID"
            value={user.user_id}
          />
          <InfoItem
            icon={<Icons.phone className="size-5" />}
            label="Mobile"
            value={user.mobile_number}
          />
          <InfoItem
            icon={<Icons.mapPin className="size-5" />}
            label="Address"
            value={user.address}
          />
        </div>
        <Separator className="my-6" />
        <div className="flex flex-col justify-end gap-2 md:flex-row">
          <Credenza open={isEditModalOpen} onOpenChange={setIsEditModalOpen}>
            <CredenzaTrigger asChild>
              <Button variant="outline" className="flex items-center">
                <Icons.edit className="mr-2 h-4 w-4" />
                Edit Profile
              </Button>
            </CredenzaTrigger>
            <CredenzaContent>
              <CredenzaHeader>
                <CredenzaTitle>Edit Profile</CredenzaTitle>
                <CredenzaDescription>
                  Update your profile information below
                </CredenzaDescription>
              </CredenzaHeader>
              <CredenzaBody>
                <EditUserForm
                  user={user}
                  setUser={setUser}
                  setModalOpen={setIsEditModalOpen}
                />
              </CredenzaBody>
            </CredenzaContent>
          </Credenza>
        </div>
      </CardContent>
    </Card>
  )
}

function InfoItem({
  icon,
  label,
  value,
}: {
  icon: React.ReactNode
  label: string
  value: string
}) {
  return (
    <div className="flex items-center space-x-3">
      <div className="flex-shrink-0 text-primary">{icon}</div>
      <div>
        <p className="text-sm font-medium text-muted-foreground">{label}</p>
        <p className="text-sm">{value}</p>
      </div>
    </div>
  )
}
