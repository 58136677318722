import * as React from "react"

import { useMediaQuery } from "@/hooks/use-media-query"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbRoute,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb"
import { Separator } from "@/components/ui/separator"

interface HeaderProps {
  children: string
  subroutes?: {
    route: string
    label: string
  }[]
}

export function Header({ children, subroutes }: HeaderProps) {
  const isDesktop = useMediaQuery("(min-width: 768px)")

  return (
    <header className="flex flex-col md:flex-row md:items-center md:gap-4 md:pt-2">
      <h1 className="text-xl font-semibold">{children}</h1>
      {isDesktop && <Separator orientation="vertical" className="h-7" />}
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbRoute href="/">Home</BreadcrumbRoute>
          </BreadcrumbItem>
          {subroutes &&
            subroutes.map((item) => (
              <React.Fragment key={item.route}>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbRoute href={item.route}>
                    {item.label}
                  </BreadcrumbRoute>
                </BreadcrumbItem>
              </React.Fragment>
            ))}
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>{children}</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
    </header>
  )
}
