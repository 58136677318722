import { createFileRoute } from "@tanstack/react-router"
import { AlertCircle } from "lucide-react"

import { getUserEmail } from "@/lib/auth"
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"

export const Route = createFileRoute("/_auth/unknown-device")({
  component: Page,
})

function obfuscateEmail(email: string): string {
  const [localPart, domainPart] = email.split("@")
  const obfuscatedLocal =
    localPart[0] + "*".repeat(localPart.length - 2) + localPart.slice(-1)
  const [domainName, domainExtension] = domainPart.split(".")
  const obfuscatedDomain =
    domainName[0] + "*".repeat(domainName.length - 2) + domainName.slice(-1)
  return `${obfuscatedLocal}@${obfuscatedDomain}.${domainExtension[0]}*${domainExtension.slice(-1)}`
}

function Page() {
  const email = getUserEmail()
  const obfuscatedEmail = obfuscateEmail(email)

  return (
    <Card className="w-full max-w-md bg-white">
      <CardHeader className="text-center">
        <div className="mx-auto mb-4 flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
          <AlertCircle className="h-6 w-6 text-yellow-600" />
        </div>
        <CardTitle className="text-2xl font-bold text-gray-600">
          Unknown Device
        </CardTitle>
      </CardHeader>
      <CardContent>
        <p className="text-center text-gray-600">
          We've detected a login attempt from a device we don't recognize. For
          your security, please verify your identity to continue.
        </p>
      </CardContent>
      <CardFooter className="flex flex-col space-y-2 text-center text-gray-600">
        The email is sent to {obfuscatedEmail}.
      </CardFooter>
    </Card>
  )
}
