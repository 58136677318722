import { useState } from "react"
import type {
  BillingOverviewType,
  PaymentAnalysisType,
  PaymentType,
  WeeklyPaymentsChartType,
} from "@/types"
import { useQuery } from "@tanstack/react-query"
import { createFileRoute } from "@tanstack/react-router"
import { formatDistanceToNow } from "date-fns"

import {
  getBillingOverview,
  getPaymentAnalysis,
  getRecentPayments,
  getWeeklyPayments,
} from "@/lib/api/billing"
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Skeleton } from "@/components/ui/skeleton"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { LineChartComponent } from "@/components/charts/linechart"
import { PieChartComponent } from "@/components/charts/piechart"
import { Header } from "@/components/header"
import { Icons } from "@/components/icons"

export const Route = createFileRoute("/_home/dashboard")({
  component: DashboardPage,
})

type TimeSelection = "daily" | "monthly" | "yearly"

function DashboardPage() {
  const [analysisType, setAnalysisType] = useState<TimeSelection>("daily")
  const [showAllPayments, setShowAllPayments] = useState(false)

  const handleValueChange = (value: TimeSelection) => {
    setAnalysisType(value)
  }

  const calculateDateRange = (type: "daily" | "monthly" | "yearly") => {
    const today = new Date()
    let startDate: Date
    let endDate: Date

    switch (type) {
      case "daily":
        startDate = new Date(today.setHours(0, 0, 0, 0))
        endDate = new Date(today.setHours(23, 59, 59, 999))
        break
      case "monthly":
        startDate = new Date(today.getFullYear(), today.getMonth(), 1)
        endDate = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          0,
          23,
          59,
          59,
          999,
        )
        break
      case "yearly":
        startDate = new Date(today.getFullYear(), 0, 1)
        endDate = new Date(today.getFullYear(), 11, 31, 23, 59, 59, 999)
        break
      default:
        throw new Error("Invalid analysis type")
    }

    return {
      startDate: startDate,
      endDate: endDate,
    }
  }

  const { data: billingOverview, isLoading: isBillingOverviewLoading } =
    useQuery<BillingOverviewType>({
      queryKey: ["billingOverview"],
      queryFn: getBillingOverview,
    })

  const { data: recentPayments } = useQuery<PaymentType[]>({
    queryKey: ["recentPayments"],
    queryFn: getRecentPayments,
  })

  const { data: weeklyPayments } = useQuery<WeeklyPaymentsChartType[]>({
    queryKey: ["weeklyPayments"],
    queryFn: getWeeklyPayments,
  })

  const { data: paymentAnalysis } = useQuery<PaymentAnalysisType[]>({
    queryKey: ["paymentAnalysis", analysisType],
    queryFn: () => {
      const { startDate, endDate } = calculateDateRange(analysisType)
      return getPaymentAnalysis(startDate, endDate)
    },
  })

  const formatDate = (dateString: string) => {
    const date = new Date(dateString)
    return formatDistanceToNow(date, { addSuffix: true })
  }

  const renderOverviewCard = (
    title: string,
    icon: keyof typeof Icons,
    value: number | string | undefined,
  ) => {
    const IconComponent = Icons[icon]
    return (
      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">{title}</CardTitle>
          {IconComponent && (
            <IconComponent className="h-4 w-4 text-muted-foreground" />
          )}
        </CardHeader>
        <CardContent className="text-2xl font-bold">
          {isBillingOverviewLoading ? (
            <Skeleton className="h-8 w-24" />
          ) : value !== undefined ? (
            typeof value === "number" ? (
              value.toLocaleString()
            ) : (
              value
            )
          ) : (
            "N/A"
          )}
        </CardContent>
      </Card>
    )
  }

  const renderPaymentsTable = () => {
    try {
      if (!recentPayments || recentPayments.length === 0) {
        return (
          <TableRow>
            <TableCell colSpan={4} className="text-center">
              No recent payments available
            </TableCell>
          </TableRow>
        )
      }

      return (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Consumer</TableHead>
              <TableHead>Amount</TableHead>
              <TableHead>Date</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {recentPayments
              .slice(0, showAllPayments ? 10 : 5)
              .map((payment: PaymentType) => (
                <TableRow key={payment.payment_id}>
                  <TableCell>{payment.consumer_name}</TableCell>
                  <TableCell>₱{payment.amount_paid.toFixed(2)}</TableCell>
                  <TableCell>{formatDate(payment.payment_date)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      )
    } catch (error) {
      console.error("Error rendering payments table:", error)
      return null
    }
  }

  return (
    <main className="space-y-4 p-4">
      <Header>Dashboard</Header>
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
        {renderOverviewCard(
          "Total Consumers",
          "users",
          billingOverview?.total_consumers,
        )}
        {renderOverviewCard(
          "Active Consumers",
          "user",
          billingOverview?.total_active_consumers,
        )}
        {renderOverviewCard(
          "Total Payments",
          "peso",
          billingOverview?.total_payments
            ? `₱ ${billingOverview.total_payments}`
            : undefined,
        )}
      </div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
        <Card className="md:col-span-2">
          <CardHeader>
            <CardTitle>Weekly Payments</CardTitle>
          </CardHeader>
          <CardContent className="pl-2 pr-10">
            <LineChartComponent data={weeklyPayments} />
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between pb-0 pt-3">
            <CardTitle>Payment Analysis</CardTitle>
            <Select value={analysisType} onValueChange={handleValueChange}>
              <SelectTrigger className="w-[8rem]">
                <SelectValue placeholder="Daily" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="daily">Daily</SelectItem>
                <SelectItem value="monthly">Monthly</SelectItem>
                <SelectItem value="yearly">Yearly</SelectItem>
              </SelectContent>
            </Select>
          </CardHeader>
          <CardContent>
            <PieChartComponent
              data={paymentAnalysis}
              dataKey="y"
              nameKey="name"
            />
          </CardContent>
        </Card>
      </div>
      <Card>
        <CardHeader className="flex flex-row items-center justify-between py-3">
          <CardTitle>Recent Payments</CardTitle>
          <Button
            onClick={() => setShowAllPayments(!showAllPayments)}
            variant="outline"
            size="sm"
          >
            {showAllPayments ? "Show Less" : "See All"}
          </Button>
        </CardHeader>
        <CardContent>{renderPaymentsTable()}</CardContent>
      </Card>
    </main>
  )
}
