import type { UserInfoType } from "@/types"
import { zodResolver } from "@hookform/resolvers/zod"
import { useMutation } from "@tanstack/react-query"
import Cookies from "js-cookie"
import { useForm } from "react-hook-form"
import * as z from "zod"

import { env } from "@/env"
import { editUser } from "@/lib/api/user"
import { useToast } from "@/hooks/use-toast"
import { Button } from "@/components/ui/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Icons } from "@/components/icons"

type SelectedUser = Pick<
  UserInfoType,
  "full_name" | "email" | "username" | "user_id" | "mobile_number" | "address"
>

const formSchema = z.object({
  full_name: z.string().min(1, "Full name is required"),
  email: z.string().min(1, "Email is required").email("Invalid email format"),
  username: z.string().min(1, "Username is required"),
  mobile_number: z
    .string()
    .min(1, "Mobile number is required")
    .regex(/^\d+$/, "Mobile number must contain only digits"),
  address: z.string().min(1, "Address is required"),
})

export function EditUserForm({
  user,
  setUser,
  setModalOpen,
}: {
  user: SelectedUser
  setUser: React.Dispatch<React.SetStateAction<SelectedUser>>
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const { toast } = useToast()

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: user.username,
      full_name: user.full_name,
      email: user.email,
      mobile_number: user.mobile_number,
      address: user.address,
    },
  })

  const mutation = useMutation({
    mutationFn: async (data: SelectedUser) => {
      await editUser(data)
    },
    onSuccess: () => {
      toast({
        description: "User updated successfully.",
      })
      setModalOpen(false)
    },
    onError: () => {
      toast({
        title: "Something went wrong!",
        description: "Please try again.",
        variant: "destructive",
      })
      setModalOpen(false)
    },
  })

  function handleSubmit(values: z.infer<typeof formSchema>) {
    const userInfo = JSON.parse(Cookies.get("user_info") as string) || {}

    userInfo.full_name = values.full_name
    userInfo.email = values.email
    userInfo.username = values.username
    userInfo.mobile_number = values.mobile_number
    userInfo.address = values.address

    Cookies.set("user_info", JSON.stringify(userInfo), {
      expires: (1 / 1440) * env.SESSION_DURATION,
    })

    mutation.mutate(values as SelectedUser)
    setUser((prevUser) => ({
      ...prevUser,
      ...values,
    }))
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(handleSubmit)}
        className="space-y-4 pb-4"
      >
        <FormField
          control={form.control}
          name="username"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Username</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="full_name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Full Name</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="mobile_number"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Mobile Number</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="address"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Address</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button disabled={mutation.isPending} type="submit" className="w-full">
          {mutation.isPending && <Icons.loader className="mr-2 animate-spin" />}
          Save Changes
        </Button>
      </form>
    </Form>
  )
}
