import * as React from "react"

import { Icons } from "@/components/icons"

interface RenderIconProps {
  connectionStatus: string
}

// Updated status colors for better visibility
const statusColors: { [key: string]: string } = {
  Active: "#28a745", // Green for active
  Delinquent: "#ffc107", // Amber for delinquent
  Disconnected: "#dc3545", // Red for disconnected
}

const getSolidColor = (status: string) => {
  return statusColors[status] || "#6c757d" // Grey for unknown status
}

const getBlinkColor = (status: string) => {
  switch (status) {
    case "Before Due":
      return "#28a745" // Green
    case "Due Today":
      return "#6c757d" // Grey
    case "Over Due":
      return "#ffc107" // Amber
    case "Disconnection":
      return "#17a2b8" // Light Blue
    default:
      return "#6c757d" // Grey
  }
}

const getAlternateColor = (status: string) => {
  switch (status) {
    case "Before Due":
    case "Due Today":
    case "Over Due":
      return "#17a2b8" // Light Blue
    case "Disconnection":
      return "#dc3545" // Red
    default:
      return "#6c757d" // Grey
  }
}

function shouldBlink(connectionStatus: string) {
  return (
    connectionStatus === "Before Due" ||
    connectionStatus === "Due Today" ||
    connectionStatus === "Over Due" ||
    connectionStatus === "Disconnection"
  )
}

export function MapIcon({ connectionStatus }: RenderIconProps) {
  const [color, setColor] = React.useState("#6c757d") // Default color

  React.useEffect(() => {
    let interval: NodeJS.Timeout

    if (shouldBlink(connectionStatus)) {
      interval = setInterval(() => {
        setColor((prevColor) =>
          prevColor === getBlinkColor(connectionStatus)
            ? getAlternateColor(connectionStatus)
            : getBlinkColor(connectionStatus),
        )
      }, 500)
    } else {
      setColor(getSolidColor(connectionStatus))
    }

    return () => clearInterval(interval)
  }, [connectionStatus])

  return (
    <div>
      <Icons.user fill={color} />
    </div>
  )
}
