import type { ConsumerInfoType } from "@/types"
import { useQuery } from "@tanstack/react-query"
import { createFileRoute } from "@tanstack/react-router"

import { getConsumer } from "@/lib/api/billing"
import { ConsumerForm } from "@/components/forms/consumer-form"
import { Header } from "@/components/header"

export const Route = createFileRoute("/_home/manage-users/edit/$id")({
  loader: async ({ params: { id } }) => {
    return { id }
  },
  component: EditConsumersPage,
})

function EditConsumersPage() {
  const { id } = Route.useLoaderData()

  const { data: consumerInfo } = useQuery<ConsumerInfoType>({
    queryKey: ["consumerInfo", id],
    queryFn: async () => {
      const consumer = await getConsumer(Number(id))
      return { ...consumer, id }
    },
  })

  return (
    <main className="space-y-4 p-4">
      <Header
        subroutes={[
          {
            route: "/manage-users",
            label: "Manage Consumers",
          },
        ]}
      >
        Edit Consumers
      </Header>
      {consumerInfo && <ConsumerForm editData={consumerInfo} />}
    </main>
  )
}
