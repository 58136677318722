import type { BillingAdjustmentType } from "@/types"
import { ColumnDef } from "@tanstack/react-table"
import { format } from "date-fns"

import { DataTableColumnHeader } from "../data-table"

export const billingAdjustmentColumns: ColumnDef<BillingAdjustmentType>[] = [
  {
    accessorKey: "consumer_name",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Consumer" />
    ),
    cell: ({ row }) => {
      return <div className="print:text-xl">{row.original.consumer_name}</div>
    },
  },
  {
    accessorKey: "total_previous_consumption",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Prev. Consumption" />
    ),
    cell: ({ row }) => {
      return (
        <div className="print:text-xl">
          {row.original.previous_consumption ||
            row.original.total_previous_consumption}
        </div>
      )
    },
  },
  {
    accessorKey: "total_previous_due_amount",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Prev. Due Amount" />
    ),
    cell: ({ row }) => {
      return (
        <div className="print:text-xl">
          {row.original.previous_due_amount ||
            row.original.total_previous_due_amount}
        </div>
      )
    },
  },
  {
    accessorKey: "total_new_consumption",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="New Consumption" />
    ),
    cell: ({ row }) => {
      return (
        <div className="print:text-xl">
          {row.original.new_consumption || row.original.total_new_consumption}
        </div>
      )
    },
  },
  {
    accessorKey: "total_new_due_amount",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="New Due Amount" />
    ),
    cell: ({ row }) => {
      return (
        <div className="print:text-xl">
          {row.original.new_due_amount || row.original.total_new_due_amount}
        </div>
      )
    },
  },
  {
    accessorKey: "adjustment_reason",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Adjustment Reason" />
    ),
    cell: ({ row }) => {
      return (
        <div className="print:text-xl">{row.original.adjustment_reason}</div>
      )
    },
  },
  {
    accessorKey: "adjustment_notes",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Adjustment Notes" />
    ),
    cell: ({ row }) => {
      return (
        <div className="print:text-xl">{row.original.adjustment_notes}</div>
      )
    },
  },
  {
    accessorKey: "adjustment_date",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Adjustment Date" />
    ),
    cell: ({ row }) => {
      const value = row.original.adjustment_date
      return (
        <div className="print:text-xl">
          {value ? format(new Date(value), "MMM dd, yyyy") : ""}
        </div>
      )
    },
  },
]
