import { Pie, PieChart } from "recharts"

import { chartConfig } from "@/config/charts"
import { cn } from "@/lib/utils"
import {
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart"

interface PieChartProps {
  data: any
  dataKey: string
  nameKey: string
  className?: string
}

export function PieChartComponent({
  data,
  dataKey,
  nameKey,
  className,
}: PieChartProps) {
  const coloredData = (data || []).map((entry: any) => ({
    ...entry,
    fill: chartConfig[entry[nameKey] as keyof typeof chartConfig]?.color,
  }))
  const totalValue = (data || []).reduce(
    (acc: number, entry: any) => acc + entry[dataKey],
    0,
  )

  return (
    <ChartContainer
      config={chartConfig}
      className={cn("mx-auto aspect-square h-[345px] w-full", className)}
    >
      <PieChart>
        {totalValue > 0 ? (
          <>
            <ChartTooltip
              content={<ChartTooltipContent nameKey={nameKey} hideLabel />}
            />
            <Pie data={coloredData} dataKey={dataKey} />
          </>
        ) : (
          <Pie
            data={[
              {
                [nameKey]: "NaN",
                [dataKey]: 1,
              },
            ]}
            dataKey={dataKey}
          />
        )}

        <ChartLegend
          content={<ChartLegendContent nameKey={nameKey} />}
          className="-translate-y-2 flex-wrap gap-2 [&>*]:basis-1/4 [&>*]:justify-center"
        />
      </PieChart>
    </ChartContainer>
  )
}
