import { useEffect, useState } from "react"
import * as AWS from "aws-sdk"
import * as CryptoJS from "crypto-js"

import { getEnv } from "@/lib/api/env"

const useAwsS3 = () => {
  const [s3, setS3] = useState<AWS.S3 | null>(null)
  const [decryptedEnv, setDecryptedEnv] = useState<any>(null)

  const initializeConfig = async () => {
    try {
      if (s3 && decryptedEnv) return

      const envResponse = await getEnv()
      const encrypted = envResponse.data
      const key = CryptoJS.enc.Utf8.parse(envResponse.key)

      if (!encrypted) {
        throw new Error("No encrypted data available")
      }

      const decrypted = CryptoJS.AES.decrypt(encrypted, key, {
        mode: CryptoJS.mode.ECB,
      })

      const env = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8))
      setDecryptedEnv(env)

      AWS.config.update({
        region: env.aws_project_region,
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: env.aws_cognito_identity_pool_id,
        }),
      })

      const s3Client = new AWS.S3({
        credentials: {
          accessKeyId: env.access_key_id,
          secretAccessKey: env.secret_access_key,
        },
        apiVersion: "2006-03-01",
        params: { Bucket: env.aws_s3_bucket },
      })

      setS3(s3Client)
    } catch (error) {
      console.error("Error initializing AWS S3 config:", error)
    }
  }

  useEffect(() => {
    initializeConfig()
  }, [])

  const downloadFileFromS3 = (key: string) => {
    if (!s3 || !decryptedEnv) return null

    const expirationTime = 5

    const params: any = {
      Key: key,
      Bucket: decryptedEnv.aws_s3_bucket,
      Expires: expirationTime,
    }

    return s3.getSignedUrl("getObject", params)
  }

  return { downloadFileFromS3 }
}

export { useAwsS3 }
