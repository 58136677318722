import "leaflet/dist/leaflet.css"
import "@/styles/leaflet-dark.css"
import "@/styles/leaflet-tooltip.css"

import type { ConsumerConnectionBillingStatusType } from "@/types"
import { Marker } from "@adamscybot/react-leaflet-component-marker"
import { MapContainer, Popup, TileLayer } from "react-leaflet"

import { Icons } from "@/components/icons"

import { MapIcon } from "./map-icon"

interface MapProps {
  position: [number, number]
  markers?: ConsumerConnectionBillingStatusType[]
}

export function Map({ position, markers }: MapProps) {
  return (
    <div>
      <MapContainer
        className="h-screen w-full"
        center={position}
        zoom={14}
        minZoom={1}
        maxZoom={18}
        scrollWheelZoom={true}
        doubleClickZoom={false}
        attributionControl={false}
      >
        <TileLayer url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" />

        {markers &&
          markers.map((marker) => (
            <Marker
              key={marker.consumer_id}
              position={[marker.lat, marker.lon]}
              icon={<MapIcon connectionStatus={marker.connection_status} />}
            >
              <Popup>
                <div className="flex flex-col gap-1">
                  <div className="flex font-semibold">
                    {marker.consumer_name}
                  </div>
                  <div className="flex">
                    <Icons.user className="mr-2 h-4 w-4" />
                    {marker.connection_status}
                  </div>
                  <div className="flex capitalize">
                    <Icons.creditCard className="mr-2 h-4 w-4" />
                    {marker.billing_status.replace("_", " ")}
                  </div>
                </div>
              </Popup>
            </Marker>
          ))}
      </MapContainer>
    </div>
  )
}
