import type { UserInfoType } from "@/types"
import Cookies from "js-cookie"

import { apiFetch } from "../utils"
import { postActivityLog } from "./log"

type SelectedUser = Pick<
  UserInfoType,
  "full_name" | "email" | "username" | "user_id" | "mobile_number" | "address"
>

interface Password {
  old_password: string
  current_password: string
  new_password: string
}

export async function editUser(data: SelectedUser) {
  const token = Cookies.get("auth-token")

  if (token) {
    const response = await apiFetch(`edit-user/`, {
      method: "PUT",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
    if (!response.ok) {
      await postActivityLog({
        menu_path: "/settings",
        sub_menu_path: "/profile",
        action: `Update User Info`,
        status_code: response.status,
        api_endpoint: response.url,
        web_url: window.location.href,
      })

      throw new Error(`Error: ${response.statusText}`)
    }

    await postActivityLog({
      menu_path: "/settings",
      sub_menu_path: "/profile",
      action: `Update User Info`,
      status_code: response.status,
      api_endpoint: response.url,
      web_url: window.location.href,
    })

    return response.json()
  }

  return null
}

export async function changePassword(data: Password) {
  const token = Cookies.get("auth-token")

  if (token) {
    const response = await apiFetch(`change-password/`, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
    if (!response.ok) {
      await postActivityLog({
        menu_path: "/settings",
        sub_menu_path: "/change-password",
        action: `Change Password`,
        status_code: response.status,
        api_endpoint: response.url,
        web_url: window.location.href,
      })

      throw new Error(`Error: ${response.statusText}`)
    }

    await postActivityLog({
      menu_path: "/settings",
      sub_menu_path: "/change-password",
      action: `Change Password`,
      status_code: response.status,
      api_endpoint: response.url,
      web_url: window.location.href,
    })

    return response.json()
  }

  return null
}
