import { ConsumerDetailsType } from "@/types"
import { useQuery } from "@tanstack/react-query"
import { createFileRoute, Link } from "@tanstack/react-router"

import { getConsumerDetails } from "@/lib/api/billing"
import { buttonVariants } from "@/components/ui/button"
import { DataTable } from "@/components/data-table"
import { Header } from "@/components/header"
import { consumerDetailsColumns } from "@/components/table/consumer-details-columns"

export const Route = createFileRoute("/_home/manage-users/")({
  component: ManageUsersPage,
})

function ManageUsersPage() {
  const {
    data: consumerDetails,
    isError,
    isLoading,
  } = useQuery<ConsumerDetailsType[]>({
    queryKey: ["consumerDetails"],
    queryFn: getConsumerDetails,
  })

  return (
    <main className="space-y-4 p-4">
      <Header>Manage Consumers</Header>
      <DataTable
        columns={consumerDetailsColumns}
        data={consumerDetails ?? []}
        search={{
          field: "name",
          label: "name",
        }}
        isLoading={isLoading}
        isError={isError}
      >
        <div className="flex w-full justify-end">
          <Link
            to="/manage-users/add"
            className={buttonVariants({ variant: "default" })}
          >
            Add Consumer
          </Link>
        </div>
      </DataTable>
    </main>
  )
}
