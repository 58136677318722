import { apiFetch } from "../utils"

export async function getEnv(): Promise<any> {
  const response = await apiFetch("himo-billing/get-env/")
  if (!response.ok) {
    throw new Error(
      `Error fetching environment variables: ${response.statusText}`,
    )
  }
  return response.json()
}
