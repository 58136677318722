import { createFileRoute } from "@tanstack/react-router"

import { ConsumerForm } from "@/components/forms/consumer-form"
import { Header } from "@/components/header"

export const Route = createFileRoute("/_home/manage-users/add")({
  component: AddConsumersPage,
})

function AddConsumersPage() {
  return (
    <main className="space-y-4 p-4">
      <Header
        subroutes={[
          {
            route: "/manage-users",
            label: "Manage Consumers",
          },
        ]}
      >
        Add Consumers
      </Header>
      <ConsumerForm />
    </main>
  )
}
