import { z } from "zod"

export const consumerSchema = z.object({
  fullName: z.string().min(1, "Full Name is required"),
  active: z.string().min(1, "Consumer status is required"),
  address: z.object({
    region: z.coerce.number().min(1, "Region is required"),
    province: z.coerce.number().min(1, "Province is required"),
    municipality: z.coerce.number().min(1, "City is required"),
    barangay: z.coerce.number().min(1, "Barangay is required"),
    postalCode: z
      .string()
      .regex(/^\d+$/, "Postal Code must contain only numbers")
      .min(1, "Postal Code is required"),
    street: z.string().min(1, "Street is required"),
  }),
  latitude: z.coerce
    .number()
    .refine((val) => !isNaN(val), { message: "Latitude is required" })
    .refine((val) => val >= -90 && val <= 90, {
      message: "Latitude must be between -90 and 90",
    })
    .refine((val) => val !== 0, {
      message: "Latitude is required",
    }),
  longitude: z.coerce
    .number()
    .refine((val) => !isNaN(val), { message: "Longitude is required" })
    .refine((val) => val >= -180 && val <= 180, {
      message: "Longitude must be between -180 and 180",
    })
    .refine((val) => val !== 0, {
      message: "Longitude is required",
    }),
  mobileNumber: z
    .string()
    .min(1, "Mobile Number is required")
    .regex(/^[0-9]+$/, "Invalid mobile number"),
  email: z.string().email("Invalid email"),
  connectionTypes: z.string().min(1, "Connection Type is required"),
  zone: z.coerce.number().min(1, "Zone is required"),
  books: z.coerce.number().optional(),
  installationDate: z
    .date()
    .refine(
      (val) => val <= new Date(),
      "Installation date cannot be in the future",
    ),
  meterReader: z.coerce.number().optional(),
  personnelInstalled: z.string(),
  meterInfoId: z.coerce.number().optional(),
})
