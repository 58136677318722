import type { LoginDataType } from "@/types"
import Cookies from "js-cookie"

import { env } from "@/env"

import { getAuthToken, saveAuth } from "../auth"
import { apiFetch, getBrowserAndOs, getDeviceId, getIpAddress } from "../utils"
import { postActivityLog } from "./log"

export async function authenticate(data: LoginDataType) {
  const res = await apiFetch(
    "login/",
    {
      method: "POST",
      body: JSON.stringify(data),
    },
    true,
  )

  const resData = await res.json()

  if (!res.ok || resData.statusCode !== "200") {
    const errorMessage =
      resData.response || "You have entered invalid credentials"
    throw new Error(errorMessage)
  }

  if (resData.statusCode === "200") {
    saveAuth(resData.user)

    await postActivityLog({
      menu_path: "",
      sub_menu_path: "",
      action: `Login (${data.username})`,
      status_code: res.status,
      api_endpoint: res.url,
      web_url: window.location.href,
    })
  }

  return res
}

export async function deviceLogin(): Promise<Response> {
  const device_id = getDeviceId()
  const ip_address = await getIpAddress()
  const { browser, os } = getBrowserAndOs()
  const url = `${window.location.origin}/trust-device`

  const data = {
    device_id,
    ip_address,
    browser,
    os,
    url,
  }

  const response = await apiFetch("device-login/", {
    method: "POST",
    body: JSON.stringify(data),
  })

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`)
  }

  return response
}

export async function markDeviceAsTrusted(deviceId: string) {
  try {
    const response = await apiFetch("mark-device-trusted/", {
      method: "POST",
      body: JSON.stringify({ device_id: deviceId }),
    })

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`)
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Failed to mark device as trusted:", error)
  }
}

export async function sendMfaOtp() {
  try {
    const generatedOtp = Math.floor(100000 + Math.random() * 900000).toString()

    Cookies.set("otp", generatedOtp, { expires: 1 / 288 })
    Cookies.set("otp-set-time", new Date().getTime().toString(), {
      expires: 1 / 288,
    })

    const userInfo = Cookies.get("user_info")
    if (!userInfo) {
      throw new Error("User info cookie not found")
    }

    const email = JSON.parse(userInfo).email
    if (!email) {
      throw new Error("Email not found in user info")
    }

    const payload = {
      email_address_list: [email],
      subject: "HIMO OTP Verification",
      body_text: "Your verification code is " + generatedOtp,
      body_html: `
        <center>
          <img src='https://rtapbucket.s3.ap-southeast-1.amazonaws.com/email-otp-assets/rtap+logo.png'>
        </center>
        <center>
          <p style='color: #707070; font-size: 16px;font-family:Helvetica;margin-top: 13px; margin-bottom: 7px;'>
            Your verification code is
          </p>
        </center>
        <center>
          <p style='color: #666666; font-size: 48px;font-family:Helvetica;margin:0;'>${generatedOtp}</p>
        </center>
        <center>
          <p style='color: #707070; font-size: 16px;font-family:Helvetica;margin-top: 13px; margin-bottom: 13px;'>
            Please do not share this code with anyone else.
          </p>
        </center>
        <center>
          <div style='width:50%;background-color:#0ca0a2;padding:20px;'>
            <center>
              <label style='color:#fff;font-size: 20px;'>Smart solutions for everyone.</label>
            </center>
          </div>
        </center>
        <center>
          <div style='display: -webkit-inline-box;margin-top: 10px;text-align: left;'>
            <img style='margin-right: 15px;' src='https://rtapbucket.s3.ap-southeast-1.amazonaws.com/email-otp-assets/rtap-logo-footer.png'>
            <p style='font-size: 14px;color: #707070;'>
              Hiraya Technology Solutions, Inc.<br>502-503 Jocfer Building, Commonwealth Avenue<br>Quezon City Philippines<br>info@hirayatech.ai
            </p>
          </div>
        </center>
      `,
    }

    const response = await apiFetch("send-email/", {
      method: "POST",
      body: JSON.stringify(payload),
    })

    if (!response.ok) {
      throw new Error("Failed to send MFA OTP.")
    }

    return await response.json()
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function refreshAccessToken(
  refreshToken: string,
): Promise<{ access: string; refresh: string }> {
  try {
    const token = getAuthToken()
    const response = await fetch(`${env.API_URL}token/refresh/`, {
      method: "POST",
      body: JSON.stringify({
        refresh: refreshToken,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        ...(token && { Authorization: `Bearer ${token}` }),
      },
    })

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`)
    }

    const data = await response.json()

    if (data.access && data.refresh) {
      Cookies.set("auth-token", data.access, {
        expires: 1 / 48,
      })
      Cookies.set("refresh-token", data.refresh, {
        expires: 1,
      })
    } else {
      throw new Error("Invalid tokens received from refresh API.")
    }

    return data
  } catch (error) {
    console.error("Failed to refresh access token:", error)
    throw error
  }
}

export async function disableUser(username: string) {
  try {
    const response = await apiFetch("disable-user/", {
      method: "PUT",
      body: JSON.stringify({ username }),
    })

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`)
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Failed to disable user:", error)
  }
}
