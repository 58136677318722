import { createFileRoute, redirect } from "@tanstack/react-router"

export const Route = createFileRoute("/_home/")({
  loader: () => {
    const menuTabs = JSON.parse(localStorage.getItem("menuTabs") || "[]")

    if (menuTabs.length > 0 && menuTabs[0].menu_route) {
      return redirect({
        to: menuTabs[0].menu_route,
      })
    }

    return redirect({
      to: "/dashboard",
    })
  },
})
