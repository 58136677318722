import * as React from "react"
import type {
  ConsumerInfoType,
  MeterInfoType,
  SubmitConsumerInfoType,
} from "@/types"
import { zodResolver } from "@hookform/resolvers/zod"
import { useMutation, useQueries } from "@tanstack/react-query"
import { useNavigate } from "@tanstack/react-router"
import { format } from "date-fns"
import Cookies from "js-cookie"
import { useForm } from "react-hook-form"
import { z } from "zod"

import {
  getBarangays,
  getMunicipalities,
  getProvinces,
  getRegions,
} from "@/lib/api/address"
import {
  addConsumer,
  editConsumer,
  getBooksZone,
  getClientZones,
  getConnectionStatus,
  getConnectionType,
  getMeterInfo,
  getMeterReaderZone,
} from "@/lib/api/billing"
import { consumerSchema } from "@/lib/schema/consumer"
import { cn } from "@/lib/utils"
import { useToast } from "@/hooks/use-toast"
import { Button } from "@/components/ui/button"
import { Calendar } from "@/components/ui/calendar"
import { Card } from "@/components/ui/card"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { ComboboxSearch } from "@/components/combobox-search"
import { Icons } from "@/components/icons"
import { MapRenderer } from "@/components/map/map-renderer"

export function ConsumerForm({ editData }: { editData?: ConsumerInfoType }) {
  const client = Cookies.get("user_client_info") || "{}"
  const location = {
    lat: editData?.lat || JSON.parse(client).lat,
    lon: editData?.lon || JSON.parse(client).lon,
  }
  const [zoneId, setZoneId] = React.useState<number | undefined>(editData?.zone)
  const [regionId, setRegionId] = React.useState<number | undefined>(
    editData?.region,
  )
  const [provinceId, setProvinceId] = React.useState<number | undefined>(
    editData?.province,
  )
  const [municipalityId, setMunicipalityId] = React.useState<
    number | undefined
  >(editData?.municipality)
  const clientId = JSON.parse(client).client_id
  const userId = Cookies.get("user_id")
  const { toast } = useToast()
  const navigate = useNavigate()

  const queryConfigs = [
    { queryKey: ["connectionType"], queryFn: getConnectionType },
    { queryKey: ["connectionStatus"], queryFn: getConnectionStatus },
    { queryKey: ["clientZones"], queryFn: getClientZones },
    {
      queryKey: ["meterInfo"],
      queryFn: async () => {
        const data = await getMeterInfo()
        return data.map((item: MeterInfoType) => ({
          id: item.meter_info_id,
          label: item.serial_number,
          value: item.serial_number,
        }))
      },
    },
    {
      queryKey: ["meterReaderZone", zoneId],
      queryFn: () => getMeterReaderZone(zoneId!),
    },
    { queryKey: ["booksZone", zoneId], queryFn: () => getBooksZone(zoneId!) },
    { queryKey: ["regions"], queryFn: getRegions },
    {
      queryKey: ["provinces", regionId],
      queryFn: () => getProvinces(regionId!),
    },
    {
      queryKey: ["municipalities", provinceId],
      queryFn: () => getMunicipalities(provinceId!),
    },
    {
      queryKey: ["barangays", municipalityId],
      queryFn: () => getBarangays(municipalityId!),
    },
  ]

  const results = useQueries({
    queries: queryConfigs,
  })

  const [
    connectionType,
    connectionStatus,
    clientZones,
    meterInfo,
    meterReaderZone,
    booksZone,
    regions,
    provinces,
    municipalities,
    barangays,
  ] = results.map((result) => result.data || [])

  const form = useForm<z.infer<typeof consumerSchema>>({
    resolver: zodResolver(consumerSchema),
    defaultValues: editData
      ? {
          fullName: editData.full_name || "",
          active: editData.connection_status || "",
          address: {
            region: editData.region,
            province: editData.province,
            municipality: editData.municipality,
            barangay: editData.barangay,
            postalCode: editData.postal_code || "",
            street: editData.street || "",
          },
          latitude: editData.lat || location?.lat,
          longitude: editData.lon || location?.lon,
          mobileNumber: editData.contact_num?.startsWith("+63")
            ? editData.contact_num.slice(3) // Omit the '+63' prefix
            : editData.contact_num || "",
          email: editData.email || "",
          connectionTypes: editData.connection_type || "",
          zone: editData.zone,
          books: editData.book,
          installationDate: editData.installation_date
            ? new Date(editData.installation_date)
            : new Date(),
          meterReader: editData.reader,
          personnelInstalled: editData.personnel_installed || "",
          meterInfoId: editData.meter_info_id,
        }
      : {
          fullName: "",
          active: "Pending",
          address: {
            region: 0,
            province: 0,
            municipality: 0,
            barangay: 0,
            postalCode: "",
            street: "",
          },
          latitude: "",
          longitude: "",
          mobileNumber: "",
          email: "",
          connectionTypes: "",
          zone: 0,
          books: 0,
          installationDate: new Date(),
          meterReader: 0,
          personnelInstalled: "",
          meterInfoId: 0,
        },
  })

  const mutation = useMutation({
    mutationFn: async ({
      id,
      payload,
    }: {
      id?: number
      payload: SubmitConsumerInfoType
    }) => {
      if (id) {
        await editConsumer(id, payload)
      } else {
        await addConsumer(payload)
      }
    },
    onSuccess: () => {
      toast({
        description: editData
          ? "Consumer updated successfully."
          : "Consumer added successfully.",
      })
      navigate({ to: "/manage-users" })
    },
    onError: () => {
      toast({
        title: "Something went wrong!",
        description: "Please try again.",
        variant: "destructive",
      })
    },
  })

  async function onSubmit(values: z.infer<typeof consumerSchema>) {
    const payload: SubmitConsumerInfoType = {
      full_name: values.fullName,
      region_code: values.address.region,
      province_code: values.address.province,
      municipality_code: values.address.municipality,
      barangay_code: values.address.barangay,
      postal_code: values.address.postalCode,
      street: values.address.street,
      contact_num: "+63" + values.mobileNumber,
      email: values.email,
      connection_type: values.connectionTypes,
      connection_status: values.active,
      zone: values.zone,
      book: values.books || 0,
      reader: values.meterReader || 0,
      lat: values.latitude,
      lon: values.longitude,
      meter_info_id: values.meterInfoId || 0,
      installation_date: values.installationDate.toISOString() || null,
      personnel_installed: values.personnelInstalled,
      client_id: clientId,
      user_id: Number(userId),
    }

    mutation.mutate({ id: editData?.id as number, payload })
  }

  const handlePositionChange = (position: [number, number]) => {
    form.setValue("latitude", position[0])
    form.setValue("longitude", position[1])
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="grid grid-cols-1 gap-4 lg:container md:max-w-[1000px]"
      >
        <div className="space-y-4">
          <Card className="space-y-2 p-4">
            <p className="text-lg font-semibold">Consumer Information</p>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <FormField
                control={form.control}
                name="fullName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Full Name <span className="text-destructive">*</span>
                    </FormLabel>
                    <FormControl>
                      <Input placeholder="Full Name" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="mobileNumber"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Mobile Number <span className="text-destructive">*</span>
                    </FormLabel>
                    <FormControl>
                      <div className="relative">
                        <Input
                          className="peer pl-12"
                          placeholder="Mobile Number"
                          {...field}
                          onChange={(e) => {
                            const value = e.target.value.replace(/\D/g, "")
                            if (value.length <= 10) {
                              field.onChange(value)
                            }
                          }}
                        />
                        <span className="pointer-events-none absolute inset-y-0 left-0 flex items-center justify-center pl-3 text-sm text-muted-foreground peer-disabled:opacity-50">
                          +63
                        </span>
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Email <span className="text-destructive">*</span>
                    </FormLabel>
                    <FormControl>
                      <Input placeholder="Email" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="active"
                render={({ field }) => (
                  <FormItem className="flex flex-col justify-center">
                    <FormLabel className="py-1">
                      Consumer Status{" "}
                      <span className="text-destructive">*</span>
                    </FormLabel>
                    <FormControl>
                      <ComboboxSearch
                        field={field}
                        options={connectionStatus}
                        placeholder="Select status"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="connectionTypes"
                render={({ field }) => (
                  <FormItem className="flex flex-col justify-center">
                    <FormLabel className="py-1">
                      Connection Type{" "}
                      <span className="text-destructive">*</span>
                    </FormLabel>
                    <FormControl>
                      <ComboboxSearch
                        field={field}
                        options={connectionType}
                        placeholder="Select type"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="zone"
                render={({ field }) => (
                  <FormItem className="flex flex-col justify-center">
                    <FormLabel className="py-1">
                      Zone <span className="text-destructive">*</span>
                    </FormLabel>
                    <FormControl>
                      <ComboboxSearch
                        field={field}
                        options={clientZones}
                        placeholder="Select zone"
                        onSelectId={(id?: string | number) =>
                          setZoneId(id as number)
                        }
                        idAsValue
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="books"
                render={({ field }) => (
                  <FormItem className="flex flex-col justify-center">
                    <FormLabel className="py-1">
                      Book <span className="text-destructive">*</span>
                    </FormLabel>
                    <FormControl>
                      <ComboboxSearch
                        field={field}
                        options={booksZone}
                        placeholder="Select book"
                        idAsValue
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="meterReader"
                render={({ field }) => (
                  <FormItem className="flex flex-col justify-center">
                    <FormLabel className="py-1">
                      Meter Reader <span className="text-destructive">*</span>
                    </FormLabel>
                    <FormControl>
                      <ComboboxSearch
                        field={field}
                        options={meterReaderZone}
                        placeholder="Select meter reader"
                        idAsValue
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </Card>
          <Card className="space-y-2 p-4">
            <p className="text-lg font-semibold">Address</p>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <FormField
                control={form.control}
                name="address.region"
                render={({ field }) => (
                  <FormItem className="flex flex-col justify-center">
                    <FormLabel className="py-1">
                      Region <span className="text-destructive">*</span>
                    </FormLabel>
                    <FormControl>
                      <ComboboxSearch
                        field={field}
                        options={regions}
                        placeholder="Select region"
                        onSelectId={(id?: string | number) =>
                          setRegionId(id as number | undefined)
                        }
                        idAsValue
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="address.province"
                render={({ field }) => (
                  <FormItem className="flex flex-col justify-center">
                    <FormLabel className="py-1">
                      Province <span className="text-destructive">*</span>
                    </FormLabel>
                    <FormControl>
                      <ComboboxSearch
                        field={field}
                        options={provinces}
                        placeholder="Select province"
                        onSelectId={(id?: string | number) =>
                          setProvinceId(id as number | undefined)
                        }
                        idAsValue
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="address.municipality"
                render={({ field }) => (
                  <FormItem className="flex flex-col justify-center">
                    <FormLabel className="py-1">
                      Municipality <span className="text-destructive">*</span>
                    </FormLabel>
                    <FormControl>
                      <ComboboxSearch
                        field={field}
                        options={municipalities}
                        placeholder="Select municipality"
                        onSelectId={(id?: string | number) =>
                          setMunicipalityId(id as number | undefined)
                        }
                        idAsValue
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="address.barangay"
                render={({ field }) => (
                  <FormItem className="flex flex-col justify-center">
                    <FormLabel className="py-1">
                      Barangay <span className="text-destructive">*</span>
                    </FormLabel>
                    <FormControl>
                      <ComboboxSearch
                        field={field}
                        options={barangays}
                        placeholder="Select barangay"
                        idAsValue
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="address.postalCode"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Postal Code <span className="text-destructive">*</span>
                    </FormLabel>
                    <FormControl>
                      <Input placeholder="Postal Code" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="address.street"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Street, Building, House No.{" "}
                      <span className="text-destructive">*</span>
                    </FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Street, Building, House No."
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </Card>
          <Card className="space-y-2 p-4">
            <p className="text-lg font-semibold">Meter Information</p>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <FormField
                control={form.control}
                name="installationDate"
                render={({ field }) => (
                  <FormItem className="flex flex-col justify-center">
                    <FormLabel className="py-1">Installation Date</FormLabel>
                    <Popover>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant={"outline"}
                            className={cn(
                              "pl-3 text-left font-normal",
                              !field.value && "text-muted-foreground",
                            )}
                          >
                            {field.value ? (
                              format(field.value, "PPP")
                            ) : (
                              <span>Pick a date</span>
                            )}
                            <Icons.calendar className="ml-auto h-4 w-4 opacity-50" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="start">
                        <Calendar
                          mode="single"
                          selected={field.value}
                          onSelect={field.onChange}
                          initialFocus
                        />
                      </PopoverContent>
                    </Popover>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="personnelInstalled"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Personnel Installed</FormLabel>
                    <FormControl>
                      <Input placeholder="Personnel Installed" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="meterInfoId"
                render={({ field }) => (
                  <FormItem className="flex flex-col justify-center">
                    <FormLabel className="py-1">Meter Info</FormLabel>
                    <FormControl>
                      <ComboboxSearch
                        field={field}
                        options={meterInfo}
                        placeholder="Select serial number"
                        idAsValue
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </Card>
        </div>
        <Card className="space-y-2 p-4">
          <p className="text-lg font-semibold">Location</p>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <FormField
              control={form.control}
              name="latitude"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    Latitude <span className="text-destructive">*</span>
                  </FormLabel>
                  <FormControl>
                    <Input type="number" placeholder="Latitude" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="longitude"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    Longitude <span className="text-destructive">*</span>
                  </FormLabel>
                  <FormControl>
                    <Input type="number" placeholder="Longitude" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="overflow-hidden rounded-lg border">
            <MapRenderer
              picker={{
                lat: location.lat,
                lng: location.lon,
              }}
              onPositionChange={handlePositionChange}
            />
          </div>
        </Card>
        <div className="flex justify-end">
          <Button
            type="submit"
            disabled={mutation.isPending}
            className="w-full md:max-w-[300px]"
          >
            {mutation.isPending && (
              <Icons.loader className="mr-2 animate-spin" />
            )}
            <span>{editData ? "Update Consumer" : "Add Consumer"}</span>
          </Button>
        </div>
      </form>
    </Form>
  )
}
