import { createFileRoute, Outlet, redirect } from "@tanstack/react-router"

import { isAuthenticated } from "@/lib/auth"
import { SidebarProvider } from "@/components/ui/sidebar"
import { AppSidebar } from "@/components/layout/app-sidebar"

export const Route = createFileRoute("/_home")({
  beforeLoad: async ({ location }) => {
    const authenticated = await isAuthenticated()

    if (!authenticated) {
      throw redirect({
        to: "/login",
        search: {
          redirect: location.href,
        },
      })
    }
  },
  component: HomeLayout,
})

function HomeLayout() {
  return (
    <SidebarProvider>
      <AppSidebar />
      <div className="w-full">
        <Outlet />
      </div>
    </SidebarProvider>
  )
}
