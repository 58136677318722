import * as React from "react"
import type { MeterInfoType } from "@/types"
import { zodResolver } from "@hookform/resolvers/zod"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useForm } from "react-hook-form"
import * as z from "zod"

import { deleteMeter } from "@/lib/api/billing"
import { useToast } from "@/hooks/use-toast"
import { Button } from "@/components/ui/button"
import {
  Credenza,
  CredenzaBody,
  CredenzaClose,
  CredenzaContent,
  CredenzaDescription,
  CredenzaFooter,
  CredenzaHeader,
  CredenzaTitle,
} from "@/components/ui/credenza"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Icons } from "@/components/icons"

import { MeterForm } from "../forms/meter-form"

interface DataProps {
  data: MeterInfoType
}

export function MeterInfoOperations({ data }: DataProps) {
  const [showDropDown, setShowDropDown] = React.useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false)
  const [showEditModal, setShowEditModal] = React.useState<boolean>(false)

  return (
    <div>
      <DropdownMenu open={showDropDown} onOpenChange={setShowDropDown}>
        <DropdownMenuTrigger>
          <div className="flex h-8 w-8 items-center justify-center rounded-md border transition-colors hover:bg-muted">
            <Icons.ellipsis className="size-4" />
            <span className="sr-only">Open</span>
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem
            onSelect={() => {
              setShowEditModal(true)
              setShowDropDown(false)
            }}
          >
            <Icons.edit className="mr-2 size-4" />
            Edit Meter
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            className="text-destructive"
            onSelect={() => {
              setShowDeleteModal(true)
              setShowDropDown(false)
            }}
          >
            <Icons.delete className="mr-2 size-4" />
            Delete Meter
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <EditMeterModal
        meterData={data}
        showModal={showEditModal}
        setShowModal={setShowEditModal}
      />
      <DeleteMeterModal
        meterId={data.meter_info_id}
        meterSerialNumber={data.serial_number}
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
      />
    </div>
  )
}

function EditMeterModal({
  meterData,
  showModal,
  setShowModal,
}: {
  meterData: MeterInfoType
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}) {
  return (
    <>
      <Credenza open={showModal} onOpenChange={setShowModal}>
        <CredenzaContent>
          <CredenzaHeader>
            <CredenzaTitle>Edit Meter</CredenzaTitle>
            <CredenzaDescription>
              Fill up meter details below.
            </CredenzaDescription>
          </CredenzaHeader>
          <CredenzaBody className="pb-4">
            <MeterForm editData={meterData} setModal={setShowModal} />
          </CredenzaBody>
        </CredenzaContent>
      </Credenza>
    </>
  )
}

function DeleteMeterModal({
  meterId,
  meterSerialNumber,
  showModal,
  setShowModal,
}: {
  meterId: number
  meterSerialNumber: string
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const queryClient = useQueryClient()
  const { toast } = useToast()

  const formSchema = z.object({
    id: z.coerce.number(),
    fullName: z
      .string()
      .min(1, "Required")
      .refine((val) => val === meterSerialNumber, {
        message: "Serial number must match.",
      }),
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      id: meterId,
      fullName: "",
    },
  })

  const mutation = useMutation({
    mutationFn: async () => {
      await deleteMeter(meterId)
    },
    onSuccess: () => {
      toast({
        description: "Meter deleted successfully.",
      })

      const invalidateQueryKeys = [
        ["meterInfo"],
        ["meterInfoAnalytics"],
        ["activeMeter"],
        ["inactiveMeter"],
        ["decommissionedMeter"],
        ["unassignedMeter"],
      ]

      invalidateQueryKeys.forEach((key) => {
        queryClient.invalidateQueries({ queryKey: key })
      })

      setShowModal(false)
    },
    onError: (error: unknown) => {
      toast({
        title: "Something went wrong!",
        description: `${error}`,
        variant: "destructive",
      })
    },
  })

  async function onSubmit() {
    mutation.mutate()
  }

  return (
    <>
      <Credenza open={showModal} onOpenChange={setShowModal}>
        <CredenzaContent>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="space-y-4 pb-4"
            >
              <CredenzaHeader>
                <CredenzaTitle>Delete Meter</CredenzaTitle>
                <CredenzaDescription>
                  You cannot undo this action!
                </CredenzaDescription>
              </CredenzaHeader>
              <CredenzaBody>
                <FormField
                  control={form.control}
                  name="fullName"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        Type "{meterSerialNumber}" to delete.
                      </FormLabel>
                      <FormControl>
                        <Input {...field} autoComplete="off" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </CredenzaBody>
              <CredenzaFooter className="flex flex-col-reverse md:flex-row">
                <CredenzaClose asChild>
                  <Button variant={"outline"}>Cancel</Button>
                </CredenzaClose>
                <Button
                  disabled={mutation.isPending}
                  type="submit"
                  variant={"destructive"}
                >
                  {mutation.isPending && (
                    <Icons.loader className="mr-2 animate-spin" />
                  )}
                  Delete
                </Button>
              </CredenzaFooter>
            </form>
          </Form>
        </CredenzaContent>
      </Credenza>
    </>
  )
}
